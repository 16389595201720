import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../hooks';
import PresentationButton from '../../../components/buttons/presentationButton';
import AbortButton from '../../../components/buttons/abortButton';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Loader from '../../../components/loader';
import FischeryDashboard from './fishery';
import MarketDashboard from './market';

const Dashboard = observer(() => {
  const {
    adminStore,
    gameStore: { game, cookie },
  } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (cookie.value) history.push('/');
    else if (game && game.status === 'running') history.push('/admin/game');
    else if (game && game.status && game.status !== 'running') history.push('/admin/result');
  })

  function handleStartGame() {
    const success = adminStore.startGame();

    if (success) {
      history.push('/admin/game')
      const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
      window._paq.push(['trackEvent', type, 'Start game', `Starting a game, Players ${game.playersSize}`]);
    }
  }

  function handleRegenerateCode() {
    adminStore.regenerateCode();
  }

  function checkReadyToPlay() {
    if (game.playersSize === 0) return false;
    return true;
  }

  return (
    game ?
      <div className="container-fluid">
        <Header game={game}></Header>
        <form name="form" onSubmit={handleStartGame}>
          <div className="row">
            <div className="col">
              <h2>Spieleinstellungen</h2>
            </div>
            <div className="col">
              <h2>Spieler</h2>
            </div>
          </div>

          {game.gameType === 'market' ? (
            <MarketDashboard />
          ) : (
            <FischeryDashboard />
          )}

          <hr />

          <p>
            <b>
              Spieldauer: {game.config.roundAmount * game.config.roundDuration}{' '}
            Minuten
          </b>
          </p>

          <div className="form-group form-inline">
            Spielcode: &nbsp;
          <input value={game?.gameCode} className="form-control" readOnly />
            <p
              className="mb-0 ml-2 text-muted display-flex"
              onClick={handleRegenerateCode}
            >
              <i className="fa fa-refresh"></i>
            </p>
          </div>

          <hr />

          <PresentationButton />

          <p>
            <button
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={!checkReadyToPlay()}
            >
              <i className="fa fa-play"></i>Spiel starten
          </button>
            <AbortButton />
          </p>

          {!game.playersSize ? (
            <i ng-if="!admin.currentGame.playersSize" className="text-muted">
              Noch keine Spieler verbunden
            </i>
          ) : null}
        </form>
        <Footer />
      </div> : <Loader />)
});

export default Dashboard;
