import React from 'react';
import FisheryResults from './resultFishery';
import MarketResults from './resultMarket';
import UtilsService from '../../services/utils';

function Result({ game }) {
  const data1 = [];
  const data2 = [];
  const data3 = [];
  const labelList = [];
  const colors = {
    purple: 'rgb(90, 20, 90)',
    green: 'rgb(195, 200, 0)',
    blue: '#2f70c0',
  };
  let lineChartData;
  let barChartData;
  let chartOptions;
  const players = {
    seller: [],
    buyer: [],
  };

  if (game.gameType === 'fishery') {
    labelList.push('Start');

    data1.push(game.config.fishAmount);
    data2.push(game.config.fishAmount);
    data3.push(0);
  }

  // Fill-up data arrays and labels
  for (var i = 0; i <= game.currentRound - 1; i++) {
    labelList.push('Runde ' + (i + 1));
    data1.push(
      game.gameType === 'fishery'
        ? game.fishAmountBeginning[i]
        : game.trades[i].max
    );
    data2.push(
      game.gameType === 'fishery'
        ? game.fishAmountRemaining[i]
        : game.trades[i].avg
    );
    data3.push(
      game.gameType === 'fishery' ? game.fishAmountTotal[i] : game.trades[i].min
    );
  }

  // If game is cancelled before anyone fished anything: data will be filled with the previous value or 0 if there is no previous value
  if (
    !data1[data1.length - 1] &&
    !data2[data2.length - 1] &&
    !data3[data3.length - 1]
  ) {
    if (game.gameType === 'fishery') {
      data1[data1.length - 1] = data1[data1.length - 2]
        ? data1[data1.length - 2]
        : 0;
      data2[data2.length - 1] = data2[data2.length - 2]
        ? data2[data2.length - 2]
        : 0;
      data3[data3.length - 1] = 0;
    } else {
      data1[data1.length - 1] = 0;
      data2[data2.length - 1] = 0;
      data3[data3.length - 1] = 0;
    }
  }

  /**
   * This creates the LINE CHART for both games.
   * Shown at every round end.
   */
  lineChartData = {
    // Chart.defaults.global.defaultFontFamily = 'Verdana';
    labels: labelList,
    datasets: [
      {
        label: game.gameType === 'fishery' ? 'Anfangsbestand' : 'Höchstpreis',
        data: data1,
        borderColor: colors.blue,
        backgroundColor: colors.blue,
        fill: false,
        pointRadius: 20,
        pointHoverRadius: 23,
      },
      {
        label:
          game.gameType === 'fishery' ? 'Restbestand' : 'Durchschnittspreis',
        data: data2,
        borderColor: game.gameType === 'fishery' ? colors.green : colors.purple,
        backgroundColor:
          game.gameType === 'fishery' ? colors.green : colors.purple,
        fill: false,
        pointRadius: 20,
        pointHoverRadius: 23,
      },
      {
        label: game.gameType === 'fishery' ? 'Fangmenge' : 'Tiefstpreis',
        data: data3,
        borderColor: game.gameType === 'fishery' ? colors.purple : colors.green,
        backgroundColor:
          game.gameType === 'fishery' ? colors.purple : colors.green,
        fill: false,
        pointRadius: 20,
        pointHoverRadius: 23,
      },
    ],
  };

  /**
   * This creates the BAR CHART at the end of the market game.
   * And creates buyers & sellers array for ranking first 3 places.
   */
  if (game.gameType === 'market') {
    barChartData = {
      labels: labelList,
      datasets: [
        {
          label: 'Durchschnittspreis',
          data: data2,
          backgroundColor: colors.purple,
          borderColor: colors.purple,
        },
      ],
    };

    for (const [, player] of Object.entries(game.players)) {
      players[player.role].push(player);
    }
  }

  /**
   * Options for both charts
   */
  chartOptions = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: game.gameType === 'fishery' ? 'Tonnen (t)' : 'Euro (€)',
          fontColor: 'black',
        },
        display: true,
        ticks: {
          beginAtZero: true,
          callback: function (label) {
            return game.gameType === 'market' ? UtilsService.formatNumber(label) : label;
          },
        },
      },

      x: {
        offset: true,
        gridLines: {
          offsetGridLines: true,
          tickMarkLength: 23,
        },
        ticks: {
          stepSize: 0.5,
          padding: 15,
        },
      },
    },
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
        labels: {
          padding: 50,
          usePointStyle: true,
        },
      },
      // datalabels: { // This does not work yet in chart.js v3s
      //   backgroundColor: function (context) {
      //     return context.dataset.borderColor;
      //   },
      //   borderRadius: 20,
      //   color: function (context) {
      //     return context.dataset.borderColor === colors.green
      //       ? 'black'
      //       : 'white';
      //   },
      //   font: {
      //     weight: 'bold',
      //   },
      //   formatter: function (value) {
      //     return game.gameType === 'market' ? UtilsService.formatNumber(value) : value;
      //   },
      // },
      tooltip: {
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label;
            if (label) label += ': ';
            return (
              label +
              (game.gameType === 'market'
                ? UtilsService.formatNumber(context.parsed.y)
                : context.parsed.y)
            );
          },
        },
      },
    },
    interaction: {
      mode: 'index',
    },
    elements: {
      point: {
        radius: 1,
        pointStyle: 'circle',
      },
      line: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: {
        top: 25,
      },
    },
  };

  return game.gameType === 'market' ? (
    <MarketResults
      game={game}
      lineChartData={lineChartData}
      barChartData={barChartData}
      chartOptions={chartOptions}
    />
  ) : (
    <FisheryResults
      game={game}
      lineChartData={lineChartData}
      chartOptions={chartOptions}
    />
  );
}

export default Result;
