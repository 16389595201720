import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../hooks';
import CurrentRound from '../../../components/currentRound';
import PresentationButton from '../../../components/buttons/presentationButton';
import AbortButton from '../../../components/buttons/abortButton';
import EndButton from '../../../components/buttons/endButton';
import Results from '../../../components/result';
import Header from '../../../components/header';
import Footer from '../../../components/footer';

const GameResultPage = observer(() => {
  const {
    gameStore: { game, cookie },
    adminStore,
  } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (cookie.value) history.push('/');
    else if (!game) history.push('/admin');
    else if (game && !game.status) history.push('/admin/dashboard');
  })

  function handleNextRoundClick() {
    adminStore.nextRound();
    history.push('/admin/game');

    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Next round', `Starting next round, Players ${game.playersSize}`]);
  }

  function handlePrint() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Print data', 'Printing the data']);
    window.print();
  }

  function handleExport() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Export data', 'Exporting the data as an excel sheet']);
  }

  function handleAbortGame() {
    const gameType = adminStore.rootStore.gameStore.game.gameType;

    adminStore.abortGame();
    history.push('/admin');

    const type = (gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Restart new game', 'Starting a new game from the final results screen']);
  }

  return (
    game && (
      <>
        <div className="container-fluid">
          <Header game={game} />
          <div className="d-flex justify-content-between">
            <div>
              <CurrentRound game={game} />
            </div>

            <div></div>
          </div>

          <div hidden={game.status === 'finished'}>
            <hr />

            <p>
              <span
                onClick={handleNextRoundClick}
                className="btn btn-primary btn-lg"
              >
                <i className="fa fa-forward"></i>Nächste Runde
              </span>
            </p>

            <PresentationButton />

            <hr />
          </div>

          {game.status === 'ended' || game.status === 'finished' ? (
            <Results admin game={game} />
          ) : null}

          <div className="mb-5 d-print-none">
            <hr />

            <div hidden={game.status === 'finished'}>
              <EndButton />
              <AbortButton />
              <hr />
            </div>

            <div hidden={game.status !== 'finished'}>
              <p className="float-right pt-1">
                <a
                  href={`${process.env.REACT_APP_API_URL}/games/${game.gameSessionId}/export?format=xlsx`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleExport}
                >
                  Daten exportieren
                </a>{' '}
                &nbsp;
                <button className="btn btn-link" onClick={handlePrint}>Daten drucken</button>
              </p>

              <span className="btn btn-primary" onClick={handleAbortGame}>
                Neues Spiel starten
                <br />
                <small>Aktuelles Spiel beenden</small>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  );
});

export default GameResultPage;
