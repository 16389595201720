import { observer } from 'mobx-react';
import React from 'react';
import RoleName from '../../../components/player/roleName';
import { useStores } from '../../../hooks';

const DashboardMarket = observer(() => {
  const { adminStore, gameStore } = useStores();

  function handleRemovePlayer(uuid) {
    adminStore.removePlayer(uuid);
  }

  function handleRoundDurationChange(event) {
    event.preventDefault();
    gameStore.updateGameConfig('roundDuration', event.target.value);
  }

  function renderPlayer() {
    const players = gameStore.game.players;

    const list = [];
    if (players) {
      for (const [uuid, player] of Object.entries(players)) {
        list.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={uuid}
          >
            <span>
              Spieler <RoleName player={player} />
            </span>

            <button
              onClick={() => handleRemovePlayer(uuid)}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </li>
        );
      }
    }

    return list;
  }

  return (
    <div className="row">
      <div className="col">
        <div className="form-group form-inline">
          {gameStore.game.config.roundAmount} Runden à &nbsp;
          <input
            value={gameStore.game.config.roundDuration}
            onChange={handleRoundDurationChange}
            type="number"
            min="1"
            max="10"
            className="text-center form-control"
            required
            autoComplete="off"
            autoCorrect="off"
          />
          &nbsp; Minuten
        </div>
      </div>

      <div className="col">
        <ul className="list-group">{renderPlayer()}</ul>

        <i hidden={gameStore.game.playersSize} className="text-muted">
          Noch keine Spieler verbunden
        </i>
      </div>
    </div>
  );
});

export default DashboardMarket;
