import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import Avatar from '../../../components/player/avatar';
import RoleName from '../../../components/player/roleName';
import Buyer from '../../../components/player/buyer';
import Seller from '../../../components/player/seller';
import UtilsService from '../../../services/utils';
import MarketModal from './marketModal';

const MarketLobby = observer(() => {
  const {
    gameStore: { game, currentPlayer: player },
    playingStore: { foundPartner, showModal, partner },
    playingStore,
  } = useStores();
  const [profit, setProfit] = useState(0);
  const [partnerNumber, setPartnerNumber] = useState('');

  useEffect(() => {
    // Run only one time after component mount
    playingStore.subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player.rounds.length === game.currentRound) {
      setProfit(player.rounds[game.currentRound - 1]);
    }
  }, [player, game.currentRound]);

  function handlePartnerNumberInput(event) {
    const number = parseInt(event.target.value);
    setPartnerNumber(number);

    playingStore.findPartner(number);
  }

  function handleSubmit(event) {
    event.preventDefault();
    playingStore.requestTradePartner(foundPartner, { actionName: 'Trade:RequestedPartner' });
    setPartnerNumber('');
  }

  useEffect(() => {
    if (partner && partner.accept) {
      playingStore.toggleModal(true);
    }
  }, [partner, playingStore]);

  useEffect(() => {
    if (partner && Object.keys(partner).length !== 0) {
      playingStore.toggleModal(true);
    }
  }, [partner, playingStore]);

  return (
    <>
      <header>
        <h2 className="d-flex align-items-center justify-content-center">
          <Avatar game={game} currentPlayer={player} index={0} />
          <span>
            Du bist
            <br />
            <RoleName player={player} />
          </span>
        </h2>
      </header>

      <main>
        <form
          hidden={
            !(
              game.currentRound > player.rounds.length &&
              game.status === 'running'
            )
          }
          name="form"
          onSubmit={handleSubmit}
          className="col-lg-5 m-auto text-center"
        >
          <h2>
            <RoleName player={player} opposite /> suchen
          </h2>

          <span>
            Nummer <RoleName player={player} opposite genitive />
          </span>
          <input
            className="form-control form-control-lg text-center mt-1"
            value={partnerNumber}
            type="number"
            required
            onChange={handlePartnerNumberInput}
            min="1"
            autoComplete="off"
            autoCorrect="off"
          />
          <br />
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            disabled={!partnerNumber || !foundPartner}
          >
            Handel starten
          </button>

          <div
            className="form-text text-muted"
          // ng-className="{invisible: form.$invalid}"
          >
            <div hidden="foundPartner">Spieler nicht gefunden.</div>
          </div>
        </form>

        <div
          hidden={game.currentRound !== player.rounds.length}
          className="text-center"
        >
          <div hidden={!player.tradepartner} className="text-center">
            <p className="lead">Erfolgreich gehandelt!</p>
            Dein Preis: €{' '}
            {UtilsService.formatNumber(player.role === 'seller'
              ? player.money + profit
              : player.money - profit)}
            <br />
            {player.role === 'buyer' ? 'Guthaben' : 'Gewinn'}: € {UtilsService.formatNumber(profit)}
          </div>

          <p hidden={player.tradepartner} className="lead">
            Kein Handel erfolgt
          </p>
        </div>

        {partner && showModal && <MarketModal resetNumber={setPartnerNumber} />}
      </main>

      <footer>
        <div className="card bg-light">
          <div className="card-body">
            {player.role === 'seller' ? (
              <Seller player={player} />
            ) : (
              <Buyer player={player} />
            )}
          </div>
        </div>
      </footer>
    </>
  );
});

export default MarketLobby;
