// This store handles the trading for the market game
// and the submittion of each round for both games
import { makeObservable, observable, action } from 'mobx';
import ApiService from "../services/api";

export default class PlayingStore {
  subscribed = false;
  showModal = false;
  submitted = {};
  payload = {};
  socket = null;
  fishAmount = 0;
  foundPartner = null;
  partner = {};
  playerChannel = null;
  accepted = false;

  constructor(rootStore) {
    // Access gameStore through rootStore
    this.gameStore = rootStore.gameStore;
    makeObservable(this, {
      subscribed: observable,
      showModal: observable,
      submitted: observable,
      payload: observable,
      partner: observable,
      foundPartner: observable,
      accepted: observable,
      setPartnerAccepted: action,
      requestTradePartner: action,
      submit: action,
      resetPartnerAndPrice: action,
      findPartner: action,
      subscribe: action,
      toggleModal: action,
      updatePrice: action,
      tradeSubmit: action,
      setRequestingTradePartner: action,
      setIncomingTradeAccept: action,
      setIncomingPrice: action,
      setPartnerConfirmedTrade: action
    });
  }

  submit(payload) {
    // Return if round was already submitted
    if (this.submitted[this.gameStore.game.currentRound]) {
      return;
    }

    this.gameStore.game.currentRound = this.gameStore.game.currentRound || 1;
    this.gameStore.submitRound(payload);
    this.submitted[this.gameStore.game.currentRound] = true;
    this.gameStore.game.fishAmount -= payload.catchAmount;
    if (this.gameStore.game.fishAmount < 0) {
      this.gameStore.game.fishAmount = 0;
    }
  }

  toggleModal(show) {
    this.showModal = show;
  }

  resetPartnerAndPrice() {
    this.partner = {};
    this.payload = {};
    this.foundPartner = null;
    this.accepted = false;
    this.toggleModal(false);
  }

  subscribe() {
    if (!this.gameStore.cookie.value) {
      return;
    }

    const playerChannel = this.gameStore.socket.subscriptions.create(
      { channel: 'PlayerChannel', data: {} },
      {
        initialized: () => {
          // console.log('PlayerChannel connection successful.');
        },
        connected: () => {
          // console.log('PlayerChannel connection subscribed.');
          this.subscribed = true;
        },
        disconnected: () => {
          // console.log('Websocket connection disconnected.');
        },
        rejected: () => {
          console.error('Websocket connection rejected.');
        },
        received: (data) => {
          if (data.actionName) {
            // console.log(`[${data.actionName}] incoming`);
            if (data.actionName === 'Trade:RequestedPartner') {
              // directly accept incoming request since we are already waiting
              if (
                this.accepted === false &&
                this.partner.waitForAccept === true &&
                this.partner.uuid === data.uuid
              ) {
                this.setIncomingTradeAccept(data);
                return
              }
              // new trade request incoming
              this.setRequestingTradePartner(data);
              return;
            }

            if (data.actionName === 'Trade:PartnerAccepted') {
              // partner accepted trade request
              this.setIncomingTradeAccept(data);
              return;
            }

            if (data.actionName === 'Trade:DeclinedRequest') {
              // cancels trade if message is from trade partner
              if (this.partner.uuid === data.uuid) {
                this.resetPartnerAndPrice();
              }
              return;
            }

            if (data.actionName === 'Trade:UpdatedPrice') {
              this.setIncomingPrice(data);
              return;
            }

            if (data.actionName === 'Trade:AcceptedTradePrice') {
              this.setPartnerConfirmedTrade();
              return;
            }
          }

          console.warn('OLD METHOD USED!');

          if (this.submitted[this.gameStore.game.currentRound]) {
            return;
          }

          if (data.partner) {
            // Reset partner and close modal if delete request from current partner
            if (data.delete && (this.partner.uuid === data.uuid)) {
              this.resetPartnerAndPrice();
              return;
            }

            // Set new partner if request is not "delete" and partner is not set
            // Check for delete if there are two people who request the same partner
            if (!Object.keys(this.partner).length && !data.delete) {
              this.setRequestingTradePartner(data);
              return;
            }

            if (data.accept) {
              this.setIncomingTradeAccept();
              return;
            }
          }

          if (this.partner) {
            if (data.price) {
              this.setIncomingPrice(data);
              return;
            }
            if (data.confirmTrade) {
              this.setPartnerConfirmedTrade();
            }
          }

          if (data.type === 'unsubscribe') {
            this.playerChannel.unsubscribe();
          }
        },
      }
    );

    // Make "this" available
    this.playerChannel = playerChannel;
  }

  // Find a partner by number who has not submitted this round yet
  findPartner(number) {
    this.foundPartner = null;

    for (const [, player] of Object.entries(this.gameStore.game.players)) {
      if (
        player.number === number &&
        player.role !== this.gameStore.currentPlayer.role &&
        player.rounds.length !== this.gameStore.game.currentRound
      ) {
        this.foundPartner = { ...player };
      }
    }
  }

  /**
   * Requests a trade with given player and opens 'waiting for answer' window
   *
   * @param {Object} player the trade partner player object
   * @param {Object} data optional, additional data to send with the request
   */
  requestTradePartner(player, data = {}) {
    const payload = {
      partner: player.uuid,
      ...data,
    };
    payload.uuid = this.gameStore.game.uuid;
    this.partner = player;
    this.partner.waitForAccept = true;
    this.sendToPartner(payload)
    .then(() => {

    })
    .catch(() => {
      this.partner = {};
      this.partner.waitForAccept = false;
      this.gameStore.triggerErrorToast();
    });
  }

  acceptIncomingTradePartner(player, data = {}) {
    const payload = {
      partner: player.uuid,
      ...data,
    };
    payload.uuid = this.gameStore.game.uuid;
    this.sendToPartner(payload)
    .then(() => {
      this.partner = player;
      this.accepted = true;
      this.partner.waitForAccept = false;
    })
    .catch(() => {
      this.gameStore.triggerErrorToast();
    });
  }

  // Partner who requested a trade
  setRequestingTradePartner(data) {
    this.partner = this.gameStore.game.players[data.partner];
    this.toggleModal(true);
    this.partner.waitForAccept = false;
    return;
  }

  // Partner has accepted own trade request
  setIncomingTradeAccept(data) {
    this.partner = this.gameStore.game.players[data.partner];
    this.accepted = true;
    this.partner.waitForAccept = false;
  }

  deleteTradePartner() {
    // Request delete for partner -> closes their modal
    if (this.partner) {
      this.sendToPartner({ partner: this.partner.uuid, uuid: this.gameStore.game.uuid, delete: true, actionName: 'Trade:DeclinedRequest' });
    }
    // Close own trade modal and reset trade data
    this.resetPartnerAndPrice();
  }

  // receive price update
  setIncomingPrice(data) {
    this.partner.price = data.price;
    this.payload.confirmTrade = false;
  }
  // send price update
  updatePrice(price) {
    if (!this.partner) {
      return;
    }

    this.partner.confirmTrade = false;
    this.payload.partner = this.partner.uuid;
    this.payload.price = parseFloat(price);
    this.payload.uuid = this.gameStore.game.uuid;
    this.payload.actionName = 'Trade:UpdatedPrice'

    this.sendToPartner(this.payload);
  }

  tradeSubmit() {
    this.payload.confirmTrade = true;
    this.payload.actionName = 'Trade:AcceptedTradePrice'

    // Submit round when partner has confirmed his trade
    if (this.partner.confirmTrade) {
      this.submit(this.payload);
    } else {
      this.sendToPartner(this.payload);
    }
  }

  setPartnerConfirmedTrade() {
    if (this.payload.confirmTrade) {
      this.submit(this.payload);
      return;
    }
    this.partner.confirmTrade = true;
  }

  setPartnerAccepted(accepted) {
    this.accepted = accepted;
  }

  sendToPartner(payload) {
    return ApiService.post('/games/' + this.gameStore.game.gameSessionId + '/send_to_partner', payload)
        .catch((err) => {
          this.handleError(err);
        });
  }

  unsubscribe() {
    if (!this.subscribed) return;
    this.playerChannel.unsubscribe();
    this.subscribed = false;
  }
}
