// This store handles everything game setup and admin related
import { makeObservable, observable, action } from 'mobx';
import ApiService from '../services/api';
import Cookie from 'mobx-cookie';

export default class AdminStore {
  loading = false;
  cookie_gameSessionId = new Cookie('gameSessionId');

  constructor(rootStore) {
    // Import of the rootStore to access the other stores
    this.rootStore = rootStore;
    makeObservable(this, {
      loading: observable,
      abortGame: action,
      togglePresentation: action,
      setLoading: action,
      cookie_gameSessionId: observable
    });
  }

  // Requests a new game from the server
  newGame(type) {
    this.setLoading(true);

    return ApiService.requestNewGame(type)
      .then((data) => {
        // Set cookie in order to share the game session id with the new window.
        const expiresValue = new Date();
        expiresValue.setSeconds(expiresValue.getSeconds() + 3600);
        this.cookie_gameSessionId.set(data.gameSessionId, { expires: expiresValue, samesite: 'Lax', domain: process.env.REACT_APP_COOKIE_DOMAIN });

        this.rootStore.gameStore.subscribeTo(data, { admin: true });
        this.setLoading(false);
      })
  }

  setLoading(loading) {
    this.loading = loading;
  }

  // Remove joined player from game
  removePlayer(uuid) {
    this.rootStore.gameStore.removePlayer(uuid);
  }

  startGame() {
    let success = false;
    if (!this.rootStore.gameStore.game.status) {
      try {
        this.rootStore.gameStore.startGame();
        success = true;
      } catch (err) {
        console.log("Couldn't start game", err);
      }
    }
    return success;
  }

  endRound(stopGame) {
    if (stopGame) {
      this.rootStore.gameStore.abortGame();
    } else {
      this.rootStore.gameStore.endRound();
    }
  }

  nextRound() {
    let success = true;
    try {
      this.rootStore.gameStore.nextRound();
    } catch (err) {
      console.error("Round couldn't be resumed.", err);
      success = false;
    }
    return success;
  }

  abortGame() {
    if (
      window.confirm(
        'Bist du sicher?\n\nAlle gespeicherten Spieldaten gehen verloren.'
      )
    ) {
      this.rootStore.gameStore.deleteCurrent({ admin: true });

      if (this.popup && this.popup.frames) {
        this.togglePresentation();
      }

      this.cookie_gameSessionId.remove();
      document.cookie = `gameSessionId=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${ process.env.REACT_APP_COOKIE_DOMAIN }`;

      return true;
    }
    return false;
  }

  regenerateCode() {
    if (window.confirm('Wirklich einen neuen Code generieren?')) {
      this.rootStore.gameStore.regenerateCode();
    }
  }

  togglePresentation() {
    if (this.popup && this.popup.frames) {
      this.popup.close();
      this.popup = null;
      return;
    }

    // http://games.localhost.com:8080
    this.popup = window.open(
      '/#!/presentation/new',
      'Projektion ' + this.gameName,
      'titlebar=no,toolbar=no,status=no,menubar=no,location=no'
    );
  }
}
