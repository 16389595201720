// This store handles everything running game and user related
import { makeObservable, observable, action } from 'mobx';
import Cookie from 'mobx-cookie';
import ApiService from '../services/api';
import LocalStorageService from '../services/localStorage';
import ActionCable from 'actioncable';

export default class GameStore {
  subscribed = false;
  gameUpdated = '';
  game = null;
  currentPlayer = null;
  socket = null;
  currentChannel = null;
  cookie = new Cookie('playerUuid');
  interval = null;
  showErrorToast = false;
  tempRound = null;
  cookie_presentation = new Cookie('gameSessionId');

  constructor(rootStore) {
    // Import of the rootStore to access the other stores
    this.rootStore = rootStore;
    makeObservable(this, {
      currentChannel: observable,
      subscribed: observable,
      game: observable,
      gameUpdated: observable,
      currentPlayer: observable,
      socket: observable,
      cookie: observable,
      showErrorToast: observable,
      tempRound: observable,
      getCurrent: action,
      connectPlayer: action,
      updateGameData: action,
      subscribeTo: action,
      initializeConnection: action,
      setCurrentPlayer: action,
      deleteCurrent: action,
      triggerErrorToast: action,
      hideErrorToast: action
    });
    this.initStore();
  }

  initStore() {
    let storedGame = LocalStorageService.getGameFromStorage();
    // Do nothing if no game and no cookie peresent. This mainly affects pupils.
    if (!storedGame && !this.cookie_presentation.value) {
      return
    }

    const p = new Promise((resolve, reject) => {
      const cookie_and_game_match = storedGame && this.cookie_presentation.value &&
        storedGame.gameSessionId === this.cookie_presentation.value;

      const no_cookie_but_game = storedGame && !this.cookie_presentation.value;

      if (cookie_and_game_match || no_cookie_but_game) {
        resolve(storedGame);
      } else {
        // Aborting a game does not clear the React store completely. Therefore we need to
        // explicitly re-fetch the current game via the cookie.
        console.log('ID mismatch, refetching game sync');
        ApiService.get('/games/' + this.cookie_presentation.value + '/resend_game_sync')
        .then(res => res.json())
        .then(res => {
          LocalStorageService.setGameInStorage(res);
          storedGame = LocalStorageService.getGameFromStorage();
          resolve(storedGame);
        })
        .catch((err) => {
          reject();
        });
      }
    })
    .then((storedGame) => {
        const isAdmin = !this.cookie.value;
        ApiService.get('/games/' + storedGame.gameSessionId)
          .then(res => {
            if (res.status === 200)
              this.subscribeTo(storedGame, { isAdmin: isAdmin });
          })
          .catch(() => {
            this.deleteCurrent({ admin: isAdmin, stale: true });
          });
      })
      .catch(() => {
        // no nothing
      });

  }

  connectPlayer(code) {
    return ApiService.join(code).then((data) => {
      this.subscribeTo(data, {});
    });
  }

  getCurrent(subOptions) {
    const game = LocalStorageService.getGameFromStorage();
    if (game) {
      this.subscribeTo(game, subOptions);
    }
  }

  subscribeTo(game, subOptions = {}, cb) {
    this.updateGameData(game);
    this.setCurrentPlayer(game);
    this.createSubscription(game, subOptions);
  }

  createSubscription(game, subOptions) {
    if (this.subscribed) {
      return;
    }

    this.channelName =
      game.gameType.charAt(0).toUpperCase() +
      game.gameType.substring(1) +
      'Channel';

    this.subOptions = subOptions;
    this.subOptions.gameSessionId = game.gameSessionId;

    this.socket = ActionCable.createConsumer(process.env.REACT_APP_WEBSOCKET_URL);
    const currentChannel = this.socket.subscriptions.create(
      { channel: this.channelName, data: this.subOptions },
      {
        initialized: () => {
          console.log('Websocket connection successful.');
        },

        connected: () => {
          this.initializeConnection();
        },

        disconnected: () => {
          console.log('Websocket connection disconnected.');
          this.removeHeartBeatInterval();
        },

        rejected: () => {
          console.error('Websocket connection rejected.');
          this.removeHeartBeatInterval();
        },

        received: (data) => {
          if (data && data.unsubscribe) {
            this.deleteCurrent();
            return;
          }

          if (data && data.type !== 'ping') {
            this.updateGameData(data);
            this.setCurrentPlayer(data);
          }
        },
      }
    );
    this.currentChannel = currentChannel;
  }

  initializeConnection() {
    this.subscribed = true;
    this.sendGame();
    this.createHeartBeatInterval(this.subOptions);
  }

  createHeartBeatInterval({ isAdmin }) {
    if (!isAdmin || this.interval) return;

    this.interval = setInterval(() => {
      this.sendGame();
    }, 15 * 1000); // 15 seconds
  }

  removeHeartBeatInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }

  setCurrentPlayer(game) {
    const uuid = this.cookie.value;
    if (uuid && game.players) {
      if (game.players[uuid]) {
        const player = game.players[uuid];
        this.currentPlayer = {
          ...this.currentPlayer,
          ...player,
        };

        // Fix for closing modals if heart beat request is send
        if (this.currentPlayer.rounds && this.currentPlayer.rounds.length) {
          if (this.currentPlayer.rounds.length !== this.tempRound) {
            this.rootStore.playingStore.resetPartnerAndPrice();
            this.tempRound = this.currentPlayer.rounds.length;
          }
        }
      } else {
        this.deleteCurrent();
      }
    }
  }

  deleteCurrent(options) {
    this.game = null;
    const storedGame = LocalStorageService.getGameFromStorage();
    LocalStorageService.deleteGameFromStorage();

    if (options && options.admin && !options.stale) {
      this.abortGame(storedGame.gameSessionId);
    } else {
      this.unsubscribe();
    }
  }

  updateGameConfig(key, value) {
    const game = this.game;
    game.config[key] = value;
    this.updateGameData(game);
  }

  updateGameData(data) {
    const game = {
      ...this.game,
      ...data,
    };
    this.game = game;

    const expiresValue = new Date();
    expiresValue.setSeconds(expiresValue.getSeconds() + 3600);

    LocalStorageService.setGameInStorage(game);

    if (game.uuid) {
      this.cookie.set(game.uuid, { expires: expiresValue });
    }

    if (game.gameType === 'market') {
      if (game.status === 'ended' || game.status === 'finished') {
        this.rootStore.playingStore.resetPartnerAndPrice();
      }
    }

    this.rootStore.presentationStore.checkFinishedRoundAndToggleModal(this.game.status);
  }

  getDataForAnimation() {
    return this.game;
  }

  unsubscribe() {
    if (!this.subscribed) {
      this.cookie.remove();
      return;
    }

    this.currentChannel.unsubscribe();
    this.subscribed = false;
    this.socket = null;
    this.currentChannel = null;

    if (this.cookie.value) {
      this.cookie.remove();
    }

    this.rootStore.playingStore.unsubscribe();
    this.game = null;
  }

  sendGame() {
    if (this.currentChannel) {
      ApiService.get('/games/' + this.game.gameSessionId + '/resend_game')
        .catch((err) => {
          this.handleError(err);
        });
    }
  }

  abortGame(gameSessionId) {
    ApiService.post('/games/' + gameSessionId + '/abort_game', {})
      .catch((err) => {
        this.handleError(err);
      });
  }

  endRound(stop) {
    ApiService.post('/games/' + this.game.gameSessionId + '/end_round', { stop: stop || false })
      .catch((err) => {
        this.handleError(err);
      });
  }

  nextRound() {
    ApiService.get('/games/' + this.game.gameSessionId + '/next_round')
      .catch((err) => {
        this.handleError(err);
      });
  }

  regenerateCode() {
    ApiService.get('/games/' + this.game.gameSessionId + '/regenerate_code')
      .catch((err) => {
        this.handleError(err);
      });
  }

  removePlayer(uuid) {
    ApiService.post('/games/' + this.game.gameSessionId + '/remove_player', { 'playerId': uuid })
      .catch((err) => {
        this.handleError(err);
      });
  }

  startGame() {
    ApiService.post('/games/' + this.game.gameSessionId + '/start_game', this.game.config)
      .catch((err) => {
        this.handleError(err);
      });
  }

  pauseGame(countdown) {
    ApiService.post('/games/' + this.game.gameSessionId + '/pause_game', { 'countdown': countdown })
      .catch((err) => {
        this.handleError(err);
      });
  }

  resumeGame() {
    ApiService.get('/games/' + this.game.gameSessionId + '/resume_game')
      .catch((err) => {
        this.handleError(err);
      });
  }

  // Submits the result of the "Fangmenge in Tonnen" (fishery game) OR
  // Submits the result of the trade (market game).
  submitRound(payload) {
    payload.uuid = this.cookie.value;
    return ApiService.post('/games/' + this.game.gameSessionId + '/submit_round', payload)
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err) {
    this.triggerErrorToast();
  }

  triggerErrorToast() {
    this.showErrorToast = true;
    setTimeout(() => {
      this.hideErrorToast();
    }, 3000);
  }

  hideErrorToast() {
    this.showErrorToast = false;
  }
}
