import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/index';
import { useHistory } from 'react-router';
import EndText from '../../../components/endText';
import Loader from '../../../components/loader';
import FisheryLobbyComponent from './fishery';
import MarketLobbyComponent from './market';
import Countdown from '../../../components/countdown';

const LobbyPage = observer(() => {
  const {
    gameStore: { game, cookie },
  } = useStores();
  const uuid = cookie.value;
  const history = useHistory();

  useEffect(() => {
    if (!game) history.push('/');
  }, [game, history])

  function renderText() {
    switch (game.status) {
      case undefined:
        return (
          <b>
            <i className="fa fa-play"></i> Warten auf Beginn...
          </b>
        );
      case 'running':
        return <Countdown hideActions />;
      case 'ended':
        return (
          <b>
            <i className="fa fa-play"></i> Warten auf nächste Runde...
          </b>
        );
      case 'finished':
        return <b>Spielende!</b>;
      default:
        break;
    }
  }

  function renderLobbyComponent() {
    if (game.gameType === 'market') return <MarketLobbyComponent />;
    return <FisheryLobbyComponent />;
  }

  return (
    game ?
      <div className={`playing ${game.gameType}`}>
        <div hidden={!uuid} className="container-fluid">
          <div className="p-1 mb-2 text-center text-primary small">
            {game ? renderText() : null}
          </div>

          {game ? <EndText game={game} /> : null}

          <div className="lobby">{game ? renderLobbyComponent() : null}</div>
        </div>

        {!uuid ? (
          <header>
            <h1>Spieler wird erstellt...</h1>
          </header>
        ) : null}
      </div> : <Loader />
  );
});

export default LobbyPage;
