import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useStores } from '../../../hooks';
import PlayerName from '../../../components/player/playerName';
import RoleName from '../../../components/player/roleName';
import Loader from '../../../components/loader';
import OceanMP4 from '../../../assets/images/bg/ocean.mp4';
import OceanWEBM from '../../../assets/images/bg/ocean.webm';

const PresentationNew = observer(() => {
  const { gameStore: { game }, presentationStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (game && game.status) {
      history.push('/presentation/running');
    }
  })

  function handleToggleFullscreen() {
    presentationStore.toggleFullscreen();
  }

  function handleStartGame() {
    presentationStore.startGame();
    history.push('/presentation/running');
  }

  function handleMouseMove() {
    presentationStore.mouseMoved();
  }

  function renderPlayers() {
    const list = [];

    if (game && game.players) {
      for (const [player_id, player] of Object.entries(game.players)) {
        list.push(
          <div key={player_id} className="p-3 h2 focused">
            <span hidden={game.gameType === 'market'}>Boot <PlayerName player={player} /></span>
            <span hidden={game.gameType === "fishery"}><RoleName player={player} /></span>
          </div>
        );
      }
    }

    return list;
  }

  return (
    game ?
      <div className={`playing ${game.gameType}`}>
        <div className="container-fluid">
          <span
            onClick={handleToggleFullscreen}
            className="text-secondary float-right"
          >
            <i className="fa fa-4x fa-arrows-alt"></i>
          </span>

          <div className="text-center pt-5" onMouseMove={handleMouseMove}>
            <p>&nbsp;</p>

            <h1 className="mt-5">
              Mit dem {game.gameType === 'market' ? 'Marktspiel' : 'Fischerspiel'} verbinden:
            </h1>
            <code className="mb-4">{process.env.REACT_APP_PRESENTATION_URL}</code>

            <h1 className="mt-5">Diesen Code eingeben:</h1>
            <code>{game.gameCode}</code>

            <hr className="my-5" />

            <h1>Verbundene Spieler: {game.playersSize || 0}</h1>

            <div className="d-flex flex-wrap justify-content-center lead">
              {renderPlayers()}
            </div>

            <i hidden={game.playersSize} className="text-muted">
              Noch keine Spieler verbunden
            </i>

            <p className="my-5">&nbsp;</p>

            {presentationStore.showButtons && (<div>
              <button
                className="btn btn-primary btn-lg"
                onClick={handleStartGame}
                disabled={!game.playersSize}
                type="button"
              >
                Spiel starten
              </button>
            </div>)}
          </div>

          {
            game.gameType === 'fishery' && (<video hidden>
              <source src={OceanMP4} type="video/mp4" />
              <source src={OceanWEBM} type="video/webm" />
            </video>)
          }
        </div>
      </div> : <Loader />
  );
});

export default PresentationNew;
