import React from 'react';
import { useStores } from '../../hooks';
import { useHistory } from 'react-router-dom';

function AbortButton() {
  const { adminStore } = useStores();
  const history = useHistory();

  function handleAbortGame(e) {
    e.preventDefault(); // Prevent start of game
    const gameType = adminStore.rootStore.gameStore.game.gameType;
    const aborted = adminStore.abortGame();

    if (aborted) {
      history.push('/admin');

      const type = (gameType === 'market') ? 'Market game' : 'Fishery game';
      window._paq.push(['trackEvent', type, 'Abort', 'Aborting game']);
    }
  }

  return (
    <button onClick={handleAbortGame} className="btn btn-link text-muted" type="button">
      Spiel abbrechen
    </button>
  );
}

export default AbortButton;
