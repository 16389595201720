import React, { useState, useEffect } from 'react';
import { useStores } from '../hooks';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import MarketSound from '../assets/sounds/market.wav';
import FisherySound from '../assets/sounds/fishery.wav';

const Countdown = observer(({ playSound, hideActions }) => {
  const history = useHistory();
  const {
    gameStore,
    gameStore: { game },
    adminStore,
  } = useStores();

  let startDuration = game.roundDuration || game.config.roundDuration;
  let [countdown, setCountdown] = useState('0' + startDuration + ':00');

  useEffect(() => {
    let interval = null;
    if (!game.isPaused || game.isPaused === "false") { // isPaused can be a string
      interval = setInterval(() => {
        const today = new Date();
        const diff = game.endDateTime * 1000 - today.getTime();

        if (diff > 0) {
          let minutes = new Date(diff).getMinutes();
          let seconds = new Date(diff).getSeconds();

          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          if (seconds < 10) {
            seconds = '0' + seconds;
          }

          setCountdown(minutes + ':' + seconds);

          if (playSound && minutes + ':' + seconds === '01:00') {
            const sound = game.gameType === 'fishery' ? FisherySound : MarketSound;
            new Audio(sound).play();
          }
        } else {
          clearInterval(interval);
          adminStore.endRound();
          if (!gameStore.cookie.value) {
            history.push('/admin/result');
            window._paq.push(['trackPageView', `Admin dashboard, ${game.gameType} game result screen after countdown run out`]);
          }
        }
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.isPaused, game.endDateTime]);

  function handlePauseGame() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Pause game', 'Pausing a game to stop the countdown']);

    gameStore.pauseGame(countdown);
  }

  function handleResumeGame() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Resume game', 'Resuming a game after pausing a countdown']);

    gameStore.resumeGame();
  }

  return (
    <div>
      <span hidden={hideActions}>
        {!game.isPaused || game.isPaused === "false" ? (
          <span onClick={handlePauseGame} className="text-muted">
            <i className="fa fa-pause"></i> Pause
          </span>
        ) : (
          <span onClick={handleResumeGame} className="text-muted">
            <i className="fa fa-play"></i> Weiter
          </span>
        )}
      </span>
      <b className="countdown"> {countdown}</b>
    </div>
  );
});

export default Countdown;
