import React from 'react';

function NotFound() {
  return (
    <>
      <header>
        <h1>Not found</h1>
      </header>
      <p>
        This page is not available.
        <a href="/">Go back.</a>
      </p>
    </>
  );
}

export default NotFound;
