import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useStores } from '../../../hooks';
import Footer from '../../../components/footer';
import Loader from '../../../components/loader';
import MarketThumbnail from '../../../assets/images/thumbnail_market.jpg';
import FisheryThumbnail from '../../../assets/images/thumbnail_fishery.jpg';

const CreateNewGamePage = observer(() => {
  const history = useHistory();
  const { adminStore, gameStore: { game, cookie }, gameStore } = useStores();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (cookie.value) history.push('/');
    else if (game && !game.status) history.push('/admin/dashboard');
    else if (game && game.status === 'running') history.push('/admin/game');
    else if (game && game.status !== 'running') history.push('/admin/result');
  })

  function handleNewFisheryGame() {
    window._paq.push(['trackEvent', 'Fishery game', 'Select game', 'Selected fishery as game']);

    adminStore
      .newGame('fishery')
      .then(() => {
        history.push('/admin/dashboard');
        window._paq.push(['trackPageView', 'Admin dashboard, fishery game settings']);
      })
      .catch(() => {
        gameStore.triggerErrorToast();
        adminStore.setLoading(false);
      });
  }

  function handleNewMarketGame() {
    window._paq.push(['trackEvent', 'Market game', 'Select game', 'Selected market as game']);

    adminStore
      .newGame('market')
      .then(() => {
        history.push('/admin/dashboard');
        window._paq.push(['trackPageView', 'Admin dashboard, market game settings']);
      })
      .catch(() => {
        gameStore.triggerErrorToast();
        adminStore.setLoading(false);
      });
  }

  function handleToggleModal() {
    setShowModal(!showModal);
  }

  return (
    adminStore ?
      <div className="container-fluid">
        <header>
          <h1>Wähle ein Spiel</h1>
        </header>

        <div className="row">
          <div className="col-6">
            <div className="card">
              <img
                onClick={handleNewMarketGame}
                className="card-img-top"
                src={MarketThumbnail}
                alt="Marktspiel"
              />

              <div className="card-body">
                <h4 className="card-title" ng-if="!admin.gameType">
                  Marktspiel
                </h4>
                <p className="card-text">
                  Jeder Spieler muss sich mit dem Spiel verbinden.
                  <br className="d-none d-lg-block" />
                  &nbsp;
                </p>

                <button
                  onClick={handleNewMarketGame}
                  disabled={adminStore.loading}
                  className="btn btn-primary"
                  type="button"
                >
                  Starten
                </button>
                <i
                  hidden={!adminStore.loading}
                  className="fa fa-spinner fa-spin fa-fw text-muted"
                ></i>

                <a
                  href="/Spielanleitung_Marktspiel.pdf"
                  className="float-right btn btn-link"
                  target="_blank"
                >
                  <i className="fa fa-file-pdf-o"></i>Spielanleitung
                </a>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <img
                onClick={handleNewFisheryGame}
                className="card-img-top"
                src={FisheryThumbnail}
                alt="Fischerspiel"
              />

              <div className="card-body">
                <h4 className="card-title" ng-if="!admin.gameType">
                  Fischerspiel
                </h4>
                <p className="card-text">
                  Bildet 5 Gruppen. Jede Gruppe muss sich mit dem Spiel verbinden.
                </p>

                <button
                  onClick={handleNewFisheryGame}
                  disabled={adminStore.loading}
                  className="btn btn-primary"
                  type="button"
                >
                  Starten
                </button>
                <i
                  hidden={!adminStore.loading}
                  className="fa fa-spinner fa-spin fa-fw text-muted"
                ></i>

                <a
                  href="/Spielanleitung_Fischerspiel.pdf"
                  className="float-right btn btn-link"
                  target="_blank"
                >
                  <i className="fa fa-file-pdf-o"></i>Spielanleitung
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          show={showModal}
          onHide={handleToggleModal}
          backdrop="static"
          keyboard={false}
          animation={false}
          dialogClassName="modal-sm modal-cache-hint"
        >
          <Modal.Header closeButton>
            <Modal.Title>Wichtiger Hinweis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Bitte löschen Sie den Browsercache bevor Sie ein Spiel auswählen.</p>
            <p>Ihr Teach Economy Team</p>
          </Modal.Body>
        </Modal>
      </div > : <Loader />
  );
});

export default CreateNewGamePage;
