import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Avatar from '../../../components/player/avatar';
import { useHistory } from 'react-router-dom';

function MarketIntro({ game, currentPlayer }) {
  const [index, setIndex] = useState(0);
  const [finished, setFinished] = useState(false);
  const history = useHistory();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    if (selectedIndex === 5) setFinished(true);
  };

  function handleGoToLobby() {
    history.push('/lobby');
  }

  return (
    <div className="container-fluid">
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null} slide={false} fade={false}>
        <Carousel.Item>
          <div className="d-block"><Avatar opposite game={game} currentPlayer={currentPlayer} index={1} /></div>
          <Carousel.Caption>
            <h5>Auf dem Wochenmarkt</h5>
            <p hidden={currentPlayer.role === 'seller'}>
              Die Apfelernte in diesem Jahr war ertragreich. Auf dem Markt
              bieten die Händler ihre Apfelkisten zum Kauf an. Ihre
              Preisvorstellungen sind dabei sehr unterschiedlich.
            </p>
            <p hidden={currentPlayer.role === 'buyer'}>
              Die Apfelernte in diesem Jahr war ertragreich. Auf dem Markt
              bietest du deine Apfelkisten zum Kauf an.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block"><Avatar game={game} currentPlayer={currentPlayer} index={2} /></div>
          <Carousel.Caption>
            <h5>Deine Rolle</h5>
            <p hidden={currentPlayer.role === 'seller'}>
              Du bist Käufer und möchtest eine Kiste Äpfel kaufen. In jeder
              Runde darf nur eine Kiste Äpfel gehandelt werden.
            </p>
            <p hidden={currentPlayer.role === 'buyer'}>
              Du bist Verkäufer und möchtest eine Kiste Äpfel verkaufen. In
              jeder Runde darf nur eine Kiste Äpfel gehandelt werden.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-money text-success"></i>
          </div>
          <Carousel.Caption>
            <h5>Auf dem Wochenmarkt</h5>
            <div hidden={currentPlayer.role === 'seller'}>
              <h5>Dein Budget</h5>
              <p>Dir stehen {currentPlayer.money} Euro zur Verfügung.</p>
            </div>
            <div hidden={currentPlayer.role === 'buyer'}>
              <h5>Deine Kosten</h5>
              <p>
                Bei der Apfelernte hattest du Ausgaben in Höhe von{' '}
                {currentPlayer.money} Euro.
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block"><Avatar opposite game={game} currentPlayer={currentPlayer} index={3} /></div>
          <Carousel.Caption>
            <h5>Dein Handelspartner</h5>
            <p hidden={currentPlayer.role === 'seller'}>
              Finde in der Klasse einen Verkäufer für eine Apfelkiste und gib
              seine Nummer ein, um zu handeln. Sprich mit dem Verkäufer und
              handle den für dich besten Preis aus.
            </p>
            <p hidden={currentPlayer.role === 'buyer'}>
              Finde in der Klasse einen Käufer für deine Apfelkiste und gib
              seine Nummer ein, um zu handeln. Sprich mit dem Käufer und handle
              den für dich besten Preis aus.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-gamepad text-info"></i>
          </div>
          <Carousel.Caption>
            <h5>
              Die Regeln <small>(1/2)</small>
            </h5>
            <p hidden={currentPlayer.role === 'seller'}>
              Du kannst nicht mehr ausgeben, als dir Budget zur Verfügung steht.
              <br />
              Du kannst auch 0,10 Cent-Beträge aushandeln.
            </p>
            <p hidden={currentPlayer.role === 'buyer'}>
              Du musst mit dem Verkauf der Apfelkiste mindestens deine Ausgaben
              wieder erwirtschaften.
              <br />
              Du kannst auch 0,10 Cent-Beträge aushandeln.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-gamepad text-info"></i>
          </div>
          <Carousel.Caption>
            <h5>
              Die Regeln <small>(2/2)</small>
            </h5>
            <p hidden={currentPlayer.role === 'seller'}>
              Dein Budget soll geheim bleiben.
              <br />
              Du bekommst in jeder Runde ein neues Budget.
            </p>
            <p hidden={currentPlayer.role === 'buyer'}>
              Deine Kosten sollen geheim bleiben.
              <br />
              Deine Kosten sind in jeder Runde unterschiedlich hoch.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {finished ? (
        <div className="py-4 text-center">
          <button onClick={handleGoToLobby} className="btn btn-lg btn-primary" type="button">
            OK, verstanden
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default MarketIntro;
