import React from 'react';
import RoleName from '../player/roleName';
import { Line, Bar } from 'react-chartjs-2';
import UtilsService from '../../services/utils';

class ResultMarket extends React.Component {
  // Disable rerender when game data gets updated
  // due to heart beat interval
  shouldComponentUpdate() {
    return false;
  }

  renderSellers() {
    const sellers = Object.values(this.props.game.players).filter(
      (p) => p.role === 'seller'
    );

    const list = sellers.map((seller) => {
      const data = [];

      for (let i = 0; i < this.props.game.roundAmount; i++) {
        data.push(
          <td key={i}>
            {i + 1 <= seller.rounds.length ? (
              <span>{UtilsService.formatNumber(seller.rounds[i])} €</span>
            ) : (
              <span className="text-muted">-</span>
            )}
          </td>
        );
      }

      return (
        <tr key={ `${ seller.role }_${ seller.number }` }>
          <td className="text-left">
            <RoleName player={seller} />
          </td>
          {data}
          <td>
            <b>{UtilsService.formatNumber(seller.roundsTotal || 0)} €</b>
          </td>
        </tr>
      );
    });
    return list;
  }

  renderBuyers() {
    const buyers = Object.values(this.props.game.players).filter(
      (p) => p.role === 'buyer'
    );

    const list = buyers.map((buyer) => {
      const data = [];

      for (let i = 0; i < this.props.game.roundAmount; i++) {
        data.push(
          <td key={i}>
            {i + 1 <= buyer.rounds.length ? (
              <span>{UtilsService.formatNumber(buyer.rounds[i])} €</span>
            ) : (
              <span className="text-muted">-</span>
            )}
          </td>
        );
      }

      return (
        <tr key={ `${ buyer.role }_${ buyer.number }` }>
          <td className="text-left">
            <RoleName player={buyer} />
          </td>
          {data}
          <td>
            <b>{UtilsService.formatNumber(buyer.roundsTotal || 0)} €</b>
          </td>
        </tr>
      );
    });
    return list;
  }

  renderTradeRows() {
    const row = [];
    const trades = this.props.game.trades[this.props.game.currentRound - 1].prices;

    for (const [price, amount] of Object.entries(trades)) {
      row.push(
        <tr key={price}>
          <td>{amount}</td>
          <td>{UtilsService.formatNumber(parseFloat(price))} €</td>
        </tr>
      );
    }

    return row;
  }

  render() {
    return (
      <>
        <table className="table table-bordered table-hover text-center">
          <thead>
            <tr>
              <th>Anzahl der Vertragsabschlüsse</th>
              <th>Handelspreis für eine Kiste Äpfel</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTradeRows()}
            <tr>
              <td>
                <b>
                  Gesamt:
                  <br />
                  {this.props.game.trades[this.props.game.currentRound - 1].total}/
                  {this.props.game.trades[this.props.game.currentRound - 1].totalMax}
                </b>
              </td>
              <td>
                <b>
                  Durchschnittspreis:
                  <br />
                  {UtilsService.formatNumber(this.props.game.trades[this.props.game.currentRound - 1].avg)} €
                </b>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="mt-5">Preisentwicklung</h3>
        <Line data={this.props.lineChartData} options={this.props.chartOptions} />

        <div hidden={this.props.game.status !== 'finished'} className="mt-5">
          <h2>Ranking</h2>

          <h3 className="text-muted pb-2">Ergebnisse der Verkäufer</h3>
          <table className="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th scope="col" className="text-left">
                  Spieler
                </th>
                <th colSpan={this.props.game.roundAmount}>Gewinn pro Runde</th>
                <th scope="col">Gesamtgewinn</th>
              </tr>
            </thead>
            <tbody>{this.renderSellers()}</tbody>
          </table>

          <h3 className="text-muted pb-2 mt-5">Ergebnisse der Käufer</h3>
          <table className="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th scope="col" className="text-left">
                  Spieler
                </th>
                <th colSpan={this.props.game.roundAmount}>Guthaben pro Runde</th>
                <th scope="col">Gesamtguthaben</th>
              </tr>
            </thead>
            <tbody>{this.renderBuyers()}</tbody>
          </table>

          <h3 className="mt-5">Entwicklung des Durchschnittspreises</h3>
          <Bar data={this.props.barChartData} options={this.props.chartOptions} />
        </div>
      </>
    )
  };
}

export default ResultMarket;
