import React from 'react';

function BuyerComponent({ player }) {
  return (
    <>
      <p>
        Du willst eine Kiste Äpfel kaufen.
        <br />
        Überlege dir, welchen Preis du bezahlen willst.
      </p>

      <p>
        Dein Budget beträgt <b>{player.money} Euro</b>.
      </p>

      <p className="text-primary font-weight-bold">
        Guthaben = Budget - Kaufpreis
      </p>

      <p>Finde in der Klasse einen Verkäufer und handle den Preis aus.</p>
    </>
  );
}

export default BuyerComponent;
