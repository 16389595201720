import React from 'react';
import { useStores } from '../../../hooks';
import PlayerName from '../../../components/player/playerName';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const RunningFishery = observer(() => {
  const {
    gameStore: { game },
  } = useStores();

  function renderPlayers() {
    const players = game.players;
    const list = [];

    for (const [uuid, player] of Object.entries(players)) {
      const rounds = toJS(player.rounds); // Disable out of bounds warning
      list.push(
        <li
          className="list-group-item d-flex justify-content-between align-items-center"
          key={uuid}
        >
          <span>
            Boot &nbsp;
            <PlayerName player={player} />
          </span>
          {rounds[game.currentRound - 1] >= 0 ? (
            <span className="badge badge-success badge-pill">
              {rounds[game.currentRound - 1]} t
            </span>
          ) : (
            <i className="text-muted">
              Noch nichts festgelegt
            </i>
          )}
        </li>
      );
    }

    return list;
  }

  return game ? <ul className="list-group">{renderPlayers()}</ul> : null;
});

export default RunningFishery;
