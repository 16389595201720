class localStorageService {
  getGameFromStorage() {
    return JSON.parse(localStorage.getItem('currentGame'));
  }

  setGameInStorage(game) {
    localStorage.setItem('currentGame', JSON.stringify(game));
  }

  deleteGameFromStorage() {
    localStorage.removeItem('currentGame');
  }

  setConfigInStorage(game) {
    localStorage.setItem('currentGameConfig', JSON.stringify(game.config));
  }
}

export default new localStorageService();