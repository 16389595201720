import React, { Component } from 'react';
import BoatMasterSheet from '../../../assets/images/boat_masterSheet.png';
import BoatRecolorSheet from '../../../assets/images/boat_hullSheet.png';
import BoatNetSheet from '../../../assets/images/boat_netSheet.png';
import BackgroundImage from '../../../assets/images/bg/fishery.png';

window.PIXI = require('phaser-ce/build/custom/pixi');
window.p2 = require('phaser-ce/build/custom/p2');
window.Phaser = require('phaser-ce/build/custom/phaser-split');

class FisheryAnimation extends Component {
  constructor(props) {
    super(props);
    this.bgImageWidth = 0;
    this.bgImageHeight = 0;
    this.boats = {};
    this.scalingVar = {};
    this.net = {};

    this.xWaypoints1 = [0.25, 0.4, 0.35, 0.25, 0.35];
    this.xWaypoints2 = [0.2, 0.15, 0.25, 0.18, 0.25];
    this.yWaypoints1 = [0.55, 0.4, 0.25, 0.25, 0.15]; // First y will hold them on Harbor-y-level
    this.yWaypoints2 = [0.58, 0.41, 0.30, 0.20, 0.135];

    this.spawnpoints = { // Depending on boat size
      1: [0.55, 0.54],
      2: [0.7, 0.4],
      3: [0.6, 0.305],
      4: [0.50, 0.235],
      5: [0.45, 0.175]
    };
    // Add paths to object
    this.paths = [];
    this.round = props.game.currentRound;

    for (let i = 0; i < this.xWaypoints1.length; i++) {
      this.paths.push({
        x1: this.xWaypoints1[i], // x-coordinate for first waypoint
        x2: this.xWaypoints2[i], // x-coordinate for second waypoint
        y1: this.yWaypoints1[i], // y-coordinate for first waypoint
        y2: this.yWaypoints2[i], // y-coordinate for second waypoint
        duration: 4000, // Math.floor((Math.random() * 1000) + 4000), // Duration is ship's time to complete the paths
        delay: Math.floor((Math.random() * 500)) // Delay before start
      });
    }

    this.gameHeight = window.innerHeight;
    this.gameWidth = this.gameHeight * 4 / 3;
  }

  shouldComponentUpdate() {
    return false; // ComponentDidUpdate not called because of this
  }

  componentDidMount() {
    this.game = new window.Phaser.Game(
      this.gameWidth,
      this.gameHeight,
      window.Phaser.AUTO,
      'phaser-container-fishery',
      {
        preload: preload.bind(this),
        create: create.bind(this),
        update: update.bind(this)
      },
      true // Set background color transparent
    );

    function preload() {
      this.game.load.spritesheet('boatImg', BoatMasterSheet, 540, 304);
      this.game.load.spritesheet('boatRecolorImg', BoatRecolorSheet, 540, 304);
      this.game.load.spritesheet('boatNet', BoatNetSheet, 540, 304);
      this.game.load.image('background', BackgroundImage);
    }

    function create() {
      // Game will continue to run even if not in focus
      this.game.stage.disableVisibilityChange = true;
      this.bgImageWidth = this.game.cache.getImage('background').width;
      this.bgImageHeight = this.game.cache.getImage('background').height;

      this.game.physics.startSystem(window.Phaser.Physics.ARCADE);
      this.addPlayerBoat();
      setTimeout(this.allLeaveHarbour, 500);
    }

    function update() {
      const game = this.props.getGameData();
      if (this.round !== game.currentRound) {
        for (const boat of Object.values(this.boats)) {
          boat.canCatch = true;
        };
        this.allLeaveHarbour();
        this.round = game.currentRound;
      }

      // Send boats back to harbour when something has been caught (rounds var will be filled with integer value as soon as a player selects the amount that should be caught)
      for (const [uuid, boat] of Object.entries(this.boats)) {
        if (game.players[uuid].rounds.length === game.currentRound && boat.canCatch) {
          this.singleReturnToHarbour(boat, uuid);
        }
      };
    }
  }

  addPlayerBoat = () => {
    for (const [uuid, player] of Object.entries(this.props.game.players)) {
      if (!this.boats[uuid]) {

        // Add the ships to the [boats] object in order to be able to access its data later
        // spawnpoints are fixed position saved in [spawnpoints] variable -> accessed by index
        // -> spawnpoints depend on the number of ships ingame
        this.boats[uuid] = Object.assign({}, player);

        let boatsSize = Object.keys(this.boats).length,
          x = this.game.world.width * this.spawnpoints[boatsSize][0],
          y = this.game.world.height * this.spawnpoints[boatsSize][1],
          bgSprite = this.game.add.sprite(0, 0, 'boatImg'),
          colorSprite = this.game.add.sprite(0, 0, 'boatRecolorImg');

        colorSprite.tint = '0x' + player.color;

        this.boats[uuid].ref = this.game.add.group();

        this.boats[uuid].ref.add(bgSprite);
        this.boats[uuid].ref.add(colorSprite);

        this.boats[uuid].ref.x = x;
        this.boats[uuid].ref.y = y;
        this.boats[uuid].harborX = this.boats[uuid].ref.x;
        this.boats[uuid].harborY = this.boats[uuid].ref.y;

        this.boats[uuid].ref.pivot.x = bgSprite.width / 4; // like anchor of sprite image
        this.boats[uuid].ref.scale.setTo(this.scalingVar[uuid], this.scalingVar[uuid]);

        this.boats[uuid].ref.callAll('animations.add', 'animations', 'idle', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], 12, true);
        this.boats[uuid].ref.callAll('animations.add', 'animations', 'sail', [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27], 12, true);
        this.boats[uuid].canCatch = true;

        this.net[uuid] = {};
        this.net[uuid].ref = this.boats[uuid].ref.addChild(this.game.add.sprite(0, 0, 'boatNet'));
        this.net[uuid].ref.scale.setTo(0, 0);
        this.net[uuid].ref.animations.add('throwOut', [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], 12, false);
        this.net[uuid].ref.animations.add('getIn', [23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12], 12, false);
        this.net[uuid].ref.animations.add('idle', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], 12, true);

        this.scalingVar[uuid] = ((this.boats[uuid].ref.y * 1.2)) / 550;

        this.idle(this.boats[uuid]);
      }
    };
    this.addPaths();
  }
  // Lets boat sail back to harbour
  singleReturnToHarbour = (boat, uuid) => {
    if (boat.ref.x - boat.harborX < 10) {
      boat.canCatch = false;
      // Return net
      this.net[uuid].ref.animations.play('getIn');

      // On 'getIn' complete
      this.net[uuid].ref.animations.currentAnim.onComplete.addOnce(() => {
        // Reset net
        this.net[uuid].ref.scale.setTo(0, 0);
        // Flip boat
        boat.ref.scale.setTo(-this.scalingVar[uuid], this.scalingVar[uuid]);
        // Offset x to make turn 'in place'
        boat.ref.x = boat.ref.x + 100;
        // Set boat animation
        if (boat.ref.x !== boat.harborX) { this.sailAnimation(boat); }
        // Add movement tween
        let tween = this.game.add.tween(boat.ref).to({
          x: [boat.harborX],
          y: [boat.harborY]
        }, 4000, 'Linear', true, false).interpolation((v, k) => {
          return window.Phaser.Math.bezierInterpolation(v, k);
        });
        tween.onComplete.add(() => {
          // Start 'idle' animation
          this.idle(boat);
        }, false);

      }, false);
    }
  }

  idle = (boat) => {
    boat.ref.callAll('play', null, 'idle');
  }

  sailAnimation = (boat) => {
    boat.ref.callAll('play', null, 'sail');
  }

  // Lets all boats that can catch something leave the harbour
  allLeaveHarbour = () => {
    for (const [uuid, boat] of Object.entries(this.boats)) {
      // Start tweening if can catch
      if (boat.canCatch === true) {
        boat.tween.start();
        this.sailAnimation(boat);
        boat.ref.scale.setTo(this.scalingVar[uuid], this.scalingVar[uuid]);
        boat.tween.onComplete.addOnce(() => {
          // NET ANIMATION HERE
          this.idle(boat);
          this.net[uuid].ref.scale.setTo(1, 1);
          this.net[uuid].ref.animations.play('throwOut');
          this.net[uuid].ref.animations.currentAnim.onComplete.addOnce(() => {
            this.net[uuid].ref.animations.play('idle');
          }, false);
        }, false);
      }
    };
  }

  addPaths = () => {
    // Add paths to the game
    let worldWidth = this.game.world.width;
    let worldHeight = this.game.world.height;

    let i = 0;
    for (const boat of Object.values(this.boats)) {
      boat.tween = this.game.add.tween(boat.ref).to({
        x: [worldWidth * this.paths[i].x1, // paths[i].x1 => first x coordinate for paths
        worldWidth * this.paths[i].x2],
        y: [worldHeight * this.paths[i].y1,
        worldHeight * this.paths[i].y2]
      }, this.paths[i].duration, window.Phaser.Easing.Circular.inOut, false, this.paths[i].delay).interpolation(this.interpolationType);
      i++;
    };
  }

  interpolationType = (v, k) => {
    return window.Phaser.Math.bezierInterpolation(v, k);
  }

  render() {
    return <div id="phaser-container-fishery" ></div>;
  }
}

export default FisheryAnimation;