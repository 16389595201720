import React from 'react';
import PlayerName from '../player/playerName';
import { Line } from 'react-chartjs-2';

class ResultFishery extends React.Component {
  // Disable rerender when game data gets updated
  // due to heart beat interval
  shouldComponentUpdate() {
    return false;
  }

  renderPlayer() {
    const players = [];
    for (const [uuid, player] of Object.entries(this.props.game.players)) {
      players.push(
        <th scope="col" className="pt-0" key={uuid}>
          <PlayerName player={player} />
        </th>
      );
    }
    return players;
  }

  renderTableRows() {
    const rows = [];
    for (let i = 0; i < this.props.game.currentRound; i++) {
      rows.push(
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{this.props.game.fishAmountBeginning[i]} t</td>
          {Object.values(this.props.game.players).map((player) => {
            return <td key={player.uuid}> {player.rounds[i] || 0} t </td>;
          })}
          <td>{this.props.game.fishAmountTotal[i]} t</td>
          <td>{this.props.game.fishAmountRemaining[i] || 0} t</td>
        </tr>
      );
    }

    return rows;
  }

  render() {
    return (
      <>
        <h2>Ergebnis</h2>
        <table
          hidden={this.props.displayType === 'presentation' && this.props.game.status !== 'finished'}
          className="table table-bordered table-hover text-center fishery"
        >
          <thead>
            <tr>
              <th></th>
              <th className="pb-0">Anfangsfisch-</th>
              <th colSpan={this.props.game.playersSize + 1} className="pb-0">
                Fangmenge
            </th>
              <th className="pb-0">Restfisch-</th>
            </tr>
            <tr>
              <th scope="col" className="pt-0">
                Fangsaison
            </th>
              <th scope="col" className="pt-0">
                bestand
            </th>
              {this.renderPlayer()}
              <th scope="col" className="pt-0">
                Gesamt
            </th>
              <th scope="col" className="pt-0">
                bestand
            </th>
            </tr>
          </thead>
          <tbody>{this.renderTableRows()}</tbody>
        </table>

        <Line data={this.props.lineChartData} options={this.props.chartOptions} />
      </>
    )
  }
}

export default ResultFishery;
