class ApiService {
  requestNewGame(type) {
    const data = { type };
    return this.post('/games', data).then(res => res.json());
  }

  join(code) {
    const data = { gameCode: parseInt(code) };
    return this.post('/games/join', data).then(res => res.json());
  }

  // Basic requests
  async get(url) {
    return await fetch(process.env.REACT_APP_API_URL + url);
  }

  async post(url, data) {
    return await fetch(process.env.REACT_APP_API_URL + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
}

export default new ApiService();
