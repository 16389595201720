import { makeObservable, observable, action } from 'mobx';

export default class PresentationStore {
  showResultModal = false;
  showEndModal = false;
  showButtons = false;
  timeoutFn = null;
  timer = null;

  constructor(rootStore) {
    this.gameStore = rootStore.gameStore;
    makeObservable(this, {
      showResultModal: observable,
      showEndModal: observable,
      showButtons: observable,
      checkFinishedRoundAndToggleModal: action,
      triggerEndModal: action,
      mouseMoved: action,
      resetResultModal: action
    });
  }

  /**
   * Toggles fullscreen on a button click
   *
   * https://blog.idrsolutions.com/2014/01/adding-fullscreen-using-javascript-api/
   * https://caniuse.com/#feat=fullscreen
   */
  toggleFullscreen() {
    if (!document.fullscreenElement && !document.msFullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  /**
   * Similiar to startGame function in admin controller.
   * Goes to running state.
   */
  startGame() {
    if (this.gameStore.game.status !== 'running') {
      this.gameStore.startGame();
    }
  }

  /**
   * Automatically show results overlay,
   * if every player has submitted the current round
   * and hide it again when next round has changed.
   */
  checkFinishedRoundAndToggleModal(status) {
    const finishedRound = status === 'ended' || status === 'finished';

    if (status === 'finished') {
      this.triggerEndModal();
    }

    if (finishedRound) {
      this.timeoutFn = setTimeout(() => {
        this.showResultModal = true;
      }, 4000); // 4 seconds
    } else {
      clearTimeout(this.timeoutFn)
    }
  };

  /**
   * Show end modal on game state finished
   */
  triggerEndModal() {
    this.showEndModal = true;

    setTimeout(() => {
      this.showEndModal = false;
    }, 6000); // 4000 + 2000
  }

  /**
   * Show action buttons on mouse move
   */
  mouseMoved() {
    this.showButtons = true;
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.showButtons = false;
    }, 2000);
  }

  resetResultModal() {
    this.showResultModal = false;
  }
}
