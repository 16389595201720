import React from 'react';

function RoleName({ player, opposite, genitive }) {
  const playerRole = {
    seller: genitive ? 'des Verkäufers' : 'Verkäufer',
    buyer: genitive ? 'des Käufers' : 'Käufer',
  };

  const partnerRole = {
    seller: playerRole.buyer,
    buyer: playerRole.seller,
  };

  return player ? <b>{opposite ? partnerRole[player.role] : playerRole[player.role] + " " + player.number}</b> : null;
}

export default RoleName;
