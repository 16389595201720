import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';

function Header({ game }) {
  const [showModal, setShowModal] = useState(false);

  function handleToggleModal() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Help', 'Showing a help screen on demand']);

    setShowModal(!showModal);
  }

  const textClasses = classNames({
    'text-primary': game.status,
  });

  return (
    <>
      <header>
        <span
          className="text-muted float-right"
          onClick={handleToggleModal}
          title="Anleitung"
        >
          <i className="fa fa-question-circle fa-2x"></i>
        </span>
        <h1>{game.gameType === 'fishery' ? 'Fischerspiel' : 'Marktspiel'}</h1>
      </header>
      <Modal
        show={showModal}
        onHide={handleToggleModal}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Anleitung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="font-weight-bold">
            <li>
              Starten Sie die Projektion und ziehen sie diese auf den Beamer.
            </li>
            <li>
              Sie können die Bildschirmanzeige verändern, falls kein Bild am
              Beamer angezeigt wird.
              <ul className="font-weight-normal">
                <li>
                  Unter Windows können Sie die Bildschirmanzeige mit der
                  Tastenkombination <kbd>Windows-Taste</kbd> (
                  <i className="fa fa-windows" aria-hidden="true"></i>) +{' '}
                  <kbd>P</kbd> verändern. Wählen Sie nach Druck der
                  Tastenkombination die Option „Erweitern“ aus.
                </li>
                <li>
                  Unter macOS wählen Sie im Menü Apple (
                  <i className="fa fa-apple" aria-hidden="true"></i>) die Option
                  „Systemeinstellungen“ aus. Dann wählen Sie „Monitore“ aus und
                  klicken auf den Tab „Anordnen“.
                </li>
              </ul>
            </li>
            <li>
              Schalten Sie die Projektion in den Vollbildmodus.
              <ul className="font-weight-normal">
                <li>
                  Unter Windows können Sie den Vollbildmodus in den gängigen
                  Browsern (Firefox, Google Chrome, Internet Explorer, Edge) mit
                  der Taste <kbd>F11</kbd> öffnen.
                </li>
                <li>
                  Unter macOS können Sie den Vollbildmodus in den gängigen
                  Browsern (Firefox, Google Chrome, Safari) mit der
                  Tastenkombination <kbd>Ctrl</kbd> + <kbd>Cmd</kbd> +{' '}
                  <kbd>F</kbd> öffnen.
                </li>
              </ul>
            </li>
          </ul>
          <ol>
            <li className={textClasses}>
              Legen Sie die Rundendauer
              {game.gameType === 'fishery'
                ? ' und die Anzahl der Runden'
                : ''}{' '}
              fest.
            </li>
            <li className={textClasses}>
              Wenn sich alle{' '}
              {game.gameType === 'fishery' ? 'Gruppen' : 'Spieler'} verbunden
              haben, starten Sie das Spiel.
            </li>
            <li>
              Starten Sie die nächste Runde, nachdem Sie die Ergebnisse mit
              Ihren Schülern besprochen haben.
            </li>
            <li>
              Falls benötigt, speichern oder exportieren Sie das Endergebnis!
            </li>
          </ol>
          <a
            href={`/Spielanleitung_${game.gameType === 'fishery' ? 'Fischerspiel' : 'Marktspiel'
              }.pdf`}
            className="btn btn-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-file-pdf-o"></i>Spielanleitung
          </a>
          <hr />

          <h5>Probleme und Lösungen für den Spielbetrieb</h5>

          <ol>
            <li className="font-weight-bold">
              Schüler und Lehrer können sich nicht mit dem Spiel verbinden.
              <ul className="font-weight-normal">
                <li>
                  Bitte stellen Sie sicher, das eine Internet- oder
                  WLAN-Verbindung im Klassenraum besteht.
                </li>
                <li>Ist die WLAN-Funktion der Handys eingeschaltet?</li>
                <li>
                  Wurde das richtige WLAN-Netz ausgewählt und das richtige
                  Passwort eingegeben?
                </li>
                <li>
                  Falls es weiterhin zu Problemen kommt, wenden Sie sich an den
                  IT-Beauftragten/ Administrator Ihrer Schule.
                </li>
              </ul>
            </li>

            <li className="font-weight-bold">
              Was passiert wenn die Internetverbindung unterbrochen ist?
              <ul className="font-weight-normal">
                <li>
                  Das Spiel speichert den aktuellen Spielstand für eine gewisse
                  Zeit. Sie können sich also jeder Zeit wieder mit dem Spiel
                  verbinden.
                </li>
                <li>
                  Um sich erneut mit dem Spiel zu verbinden, laden Sie das
                  Browserfenster neu.
                  <br />
                  Klicken sie dazu im jeweiligen Browser auf „diese Seite neu
                  laden“ (Kreispfeil).
                  <br />
                  Spieler und Lehrer sollten jetzt wieder in das letzte Spiel
                  gelangen und weiterspielen können.
                </li>
              </ul>
            </li>

            <li className="font-weight-bold">
              Das Spiel lädt sehr langsam und die Grafiken erscheinen sehr spät.
              <ul className="font-weight-normal">
                <li>
                  Stellen Sie sicher, dass der neuste Browser auf dem Lehrer-PC
                  installiert ist.
                </li>
                <li>
                  Sofern Sie die Wahl / Möglichkeit haben, nutzen Sie den Google
                  Chrome, Firefox oder Microsoft Edge Browser. Vom Gebrauch des
                  alten Microsoft Internet Explorers raten wir ab.
                </li>
                <li>
                  Prüfen Sie auf dem Lehrer-PC, ob eventuell parallel viele
                  Anwendungen im Hintergrund laufen oder in der Ansicht
                  minimiert sind.
                </li>
                <li>
                  Weisen Sie die Schüler an, alle im Hintergrund laufenden Apps
                  auf ihren Handys zu schließen.
                </li>
                <li>Stoppen und starten sie das Spiel erneut.</li>
                <li>
                  Sollten die oben genannten Punkte nicht helfen, starten Sie
                  den PC und die Handys neu.
                </li>
                <li>
                  Prüfen Sie, ob die Internetgeschwindigkeit zu gering ist.
                  Öffnen sie dazu eine andere Webseite, um zu testen, ob und wie
                  schnell sich diese Seite aufbaut.
                </li>
              </ul>
            </li>

            <li className="font-weight-bold">
              Das Spiel wird in der Beameransicht viel zu klein oder zu groß
              angezeigt.
              <ul className="font-weight-normal">
                <li>
                  Bitte maximieren Sie zuerst die Beameransicht. Klicken sie
                  dazu rechts oben im Browserfenster auf das entsprechende
                  Symbol.
                </li>
                <li>
                  Sollte das Problem weiterhin bestehen, ist vermutlich die
                  Beameransicht auf eine 16:9 oder 4:3 Auflösung optimiert und
                  kann bei falscher Auflösung des Beamers nicht korrekt
                  angezeigt werden.
                  <br />
                  Die Auflösung des Beamers kann in den meisten Fällen wie folgt
                  umgestellt werden:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Klicken Sie mit der rechten Maustaste auf eine freie
                      Stelle auf dem Desktop.
                    </li>
                    <li>
                      Wählen Sie „Anzeigeeinstellungen/Bildschirmauflösung“ aus.
                    </li>
                    <li>
                      Klicken Sie auf „Identifizieren“ unterhalb der
                      Bildschirmdarstellungen, um zu erkennen, welche
                      Bildschirmnummer zum Beamer gehört.
                    </li>
                    <li>Wählen Sie den entsprechenden Bildschirm aus.</li>
                    <li>
                      Ändern Sie die Auflösung auf das Maximum (optimal 1920 x
                      1080).
                    </li>
                    <li>
                      Sollte die Funktion „Auflösung“ nicht sichtbar sein,
                      klicken Sie bitte auf „Erweiterte Einstellungen“ und
                      wiederholen Sie den Schritt „e“.
                    </li>
                  </ol>
                </li>
              </ul>
            </li>

            <li className="font-weight-bold">
              Das Spiel wird auf einigen Handys viel zu groß dargestellt
              (Schriften und Bilder überlagern sich).
              <ul className="font-weight-normal">
                <li>
                  Die Auflösung des Handys ist einfach zu klein und kann das
                  Spiel nicht korrekt darstellen.
                  <br />
                  Versuchen Sie das Handy zu drehen, sodass das Spiel quer
                  angezeigt wird.
                </li>
                <li>
                  Der Browser, welcher auf dem Handy verwendet wird, ist zu alt
                  und benötigt ein Update.
                </li>
                <li>
                  Auf alten Apple-Geräten kann es vorkommen, dass der Safari
                  Browser das Spiel nicht darstellen kann. Wechseln Sie zu einem
                  anderen Browser, z. B. Google Chrome für iOS.
                </li>
              </ul>
            </li>
          </ol>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
