import React, { Component } from 'react';
import Buyer from './buyer';

import BoothBody1 from '../../../assets/images/booths/booth_1_bdy.png';
import BoothRoof1 from '../../../assets/images/booths/booth_1_roof.png';
import BoothBody2 from '../../../assets/images/booths/booth_2_bdy.png';
import BoothRoof2 from '../../../assets/images/booths/booth_2_roof.png';
import BoothBody3 from '../../../assets/images/booths/booth_3_bdy.png';
import BoothRoof3 from '../../../assets/images/booths/booth_3_roof.png';
import BoothBody4 from '../../../assets/images/booths/booth_4_bdy.png';
import BoothRoof4 from '../../../assets/images/booths/booth_4_roof.png';
import BoothBody5 from '../../../assets/images/booths/booth_5_bdy.png';
import BoothRoof5 from '../../../assets/images/booths/booth_5_roof.png';
import BoothBody6 from '../../../assets/images/booths/booth_6_bdy.png';
import BoothRoof6 from '../../../assets/images/booths/booth_6_roof.png';
import BoothBody7 from '../../../assets/images/booths/booth_7_bdy.png';
import BoothRoof7 from '../../../assets/images/booths/booth_7_roof.png';
import BoothBody8 from '../../../assets/images/booths/booth_8_bdy.png';
import BoothRoof8 from '../../../assets/images/booths/booth_8_roof.png';
import BoothBody9 from '../../../assets/images/booths/booth_9_bdy.png';
import BoothRoof9 from '../../../assets/images/booths/booth_9_roof.png';
import BoothBody10 from '../../../assets/images/booths/booth_10_bdy.png';
import BoothRoof10 from '../../../assets/images/booths/booth_10_roof.png';
import BoothBody11 from '../../../assets/images/booths/booth_11_bdy.png';
import BoothRoof11 from '../../../assets/images/booths/booth_11_roof.png';
import BoothBody12 from '../../../assets/images/booths/booth_12_bdy.png';
import BoothRoof12 from '../../../assets/images/booths/booth_12_roof.png';
import BoothBody13 from '../../../assets/images/booths/booth_13_bdy.png';
import BoothRoof13 from '../../../assets/images/booths/booth_13_roof.png';
import BoothBody14 from '../../../assets/images/booths/booth_14_bdy.png';
import BoothRoof14 from '../../../assets/images/booths/booth_14_roof.png';

import BackgroundImage from '../../../assets/images/bg/market.jpg';
import Malebody1 from '../../../assets/images/male01_clothes.png'
import Malecap1 from '../../../assets/images/male01_headprop01.png'
import Maleskin1 from '../../../assets/images/male01_skin01.png'
import Malebody2 from '../../../assets/images/male02_clothes.png'
import Malecap2 from '../../../assets/images/male02_headprop01.png'
import Maleskin2 from '../../../assets/images/male02_skin01.png'
import Femalebody1 from '../../../assets/images/female01_clothes.png'
import Femalecap1 from '../../../assets/images/female01_headprop01.png'
import Femaleskin1 from '../../../assets/images/female01_skin01.png'
import Femalebody2 from '../../../assets/images/female02_clothes.png'
import Femalecap2 from '../../../assets/images/female02_headprop01.png'
import Femaleskin2 from '../../../assets/images/female02_skin01.png'

window.PIXI = require('phaser-ce/build/custom/pixi');
window.p2 = require('phaser-ce/build/custom/p2');
window.Phaser = require('phaser-ce/build/custom/phaser-split');

class MarketAnimation extends Component {
  constructor(props) {
    super(props);
    this.frameCounter = 0;
    this.gameHeight = window.innerHeight;
    this.gameWidth = this.gameHeight * 4 / 3;
    this.currentGame = props.game;
    this.round = props.game.currentRound;
    this.players = {};
    this.scalingFactor = 1;
    this.extremities = ['body', 'skin', 'cap'];
    this.playerGroup = null;
    this.allShops = [];
    this.sellers = {};
    this.shopIndexes = this.shuffle([0, 1, 2, 3, 3, 4, 5, 6, 7, 7, 8, 9, 10, 11, 12, 13]); //two times 3 and 7

    //Hardcoded Coordinates
    this.allPaths = {
      0: {
        x: [0.50, 0.50, 0.63, 0.62],
        y: [0.36, 0.27, 0.25, 0.22],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      }, // face right
      1: {
        x: [0.50, 0.50, 0.41, 0.63, 0.46, 0.41],
        y: [0.36, 0.48, 0.6, 0.82, 0.9, 0.86],
        s: 1000,
        face: 'idlewalkUpLeft',
        scale: 1
      },//face upleft
      2: {
        x: [0.50, 0.50, 0.41, 0.31, 0.21, 0.21, 0.26],
        y: [0.36, 0.48, 0.6, 0.6, 0.58, 0.76, 0.74],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: -1
      },//face up
      3: {
        x: [0.50, 0.50, 0.41, 0.52],
        y: [0.36, 0.48, 0.6, 0.59],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: -1
      },//face downright
      4: {
        x: [0.50, 0.64],
        y: [0.36, 0.4],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      },//face right
      5: {
        x: [0.50, 0.50, 0.41, 0.31, 0.21, 0.19, 0.14],
        y: [0.36, 0.48, 0.6, 0.6, 0.58, 0.74, 0.8],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: 1
      },//face up
      6: {
        x: [0.50, 0.50, 0.41, 0.31, 0.18],
        y: [0.36, 0.48, 0.6, 0.6, 0.58],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: 1
      },//face downleft
      7: {
        x: [0.50, 0.50, 0.41, 0.31, 0.22, 0.23],
        y: [0.36, 0.48, 0.6, 0.6, 0.58, 0.48],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      },//face right
      8: {
        x: [0.50, 0.50, 0.63, 0.81],
        y: [0.36, 0.27, 0.25, 0.27],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      },//face right
      9: {
        x: [0.50, 0.67, 0.72, 0.78, 0.7, 0.80],
        y: [0.36, 0.42, 0.55, 0.65, 0.78, 0.87],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      },// face right
      10: {
        x: [0.50, 0.50, 0.32, 0.27, 0.2],
        y: [0.36, 0.27, 0.31, 0.34, 0.39],
        s: 1000,
        face: 'idlewalkLeft',
        scale: 1
      }, // face left
      11: {
        x: [0.50, 0.50, 0.44],
        y: [0.36, 0.27, 0.24],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      }, // face right
      12: {
        x: [0.50, 0.50, 0.34],
        y: [0.36, 0.27, 0.28],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: 1
      }, // face downleft
      13: {
        x: [0.50, 0.67, 0.72, 0.79],
        y: [0.36, 0.42, 0.55, 0.65],
        s: 1000,
        face: 'idlewalkLeft',
        scale: -1
      },// face right
      14: {
        x: [0.50, 0.50, 0.41, 0.63, 0.7],
        y: [0.36, 0.48, 0.6, 0.82, 0.74],
        s: 1000,
        face: 'idlewalkUpLeft',
        scale: 1
      },//face upleft
      15: {
        x: [0.50, 0.45],
        y: [0.36, 0.42],
        s: 1000,
        face: 'idlewalkDownLeft',
        scale: 1
      }//face downleft
    };
    this.shopPosition = [
      { x: 0.47, y: -0.03 },       //0
      { x: 0.32, y: 0.61 },        //1
      { x: 0.22, y: 0.53 },        //2
      { x: 0.49, y: 0.47 },        //3
      { x: 0.64, y: 0.22 },        //4
      { x: -0.06, y: 0.53 },       //5
      { x: -0.03, y: 0.35 },       //6
      { x: 0.22, y: 0.27 },        //7
      { x: 0.72, y: 0.02 },        //8
      { x: 0.736, y: 0.607 },      //9
      { x: 0.02, y: 0.12 },        //10
      { x: 0.31, y: -0.01 },       //11
      { x: 0.15, y: 0.03 },        //12
      { x: 0.72, y: 0.40 }        //13
    ];
    this.sellerPositionOffset = [
      { x: 0.63 * this.gameWidth, y: 0.18 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 }, //0 - left
      { x: 0.41 * this.gameWidth, y: 0.79 * this.gameHeight, facing: 'idlewalkDownLeft', scaling: -1 }, //1 - downright
      { x: 0.28 * this.gameWidth, y: 0.73 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: 1 }, //2 - upleft
      { x: 0.67 * this.gameWidth, y: 0.63 * this.gameHeight, facing: 'idlewalkDownLeft', scaling: -1 }, //3 - upleft
      { x: 0.675 * this.gameWidth, y: 0.31 * this.gameHeight, facing: 'idlewalkDownLeft', scaling: 1 },//4 - downleft
      { x: 0.1 * this.gameWidth, y: 0.77 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: -1 }, //5 - - upright
      { x: 0.17 * this.gameWidth, y: 0.55 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: -1 }, //6 - upright
      { x: 0.43 * this.gameWidth, y: 0.4 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: -1 }, //7 - left
      { x: 0.83 * this.gameWidth, y: 0.25 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 }, //8 - left
      { x: 0.81 * this.gameWidth, y: 0.83 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 }, //9 - upleft
      { x: 0.19 * this.gameWidth, y: 0.36 * this.gameHeight, facing: 'idlewalkLeft', scaling: -1 }, //10 - right
      { x: 0.45 * this.gameWidth, y: 0.20 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 }, //11 - left
      { x: 0.3 * this.gameWidth, y: 0.25 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: -1 }, //12 - upright
      { x: 0.78 * this.gameWidth, y: 0.6 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 }, //13 - left
      { x: 0.55 * this.gameWidth, y: 0.59 * this.gameHeight, facing: 'idlewalkUpLeft', scaling: 1 },//14 <= shop 4 (double booth)
      { x: 0.24 * this.gameWidth, y: 0.43 * this.gameHeight, facing: 'idlewalkLeft', scaling: 1 },   //15 <= shop 7 (double booth)
    ];

  }

  shouldComponentUpdate() {
    return false; // ComponentDidUpdate not called because of this
  }

  componentDidMount() {
    this.game = new window.Phaser.Game(
      this.gameWidth,
      this.gameHeight,
      window.Phaser.AUTO,
      'phaser-container-market',
      {
        preload: preload.bind(this),
        create: create.bind(this),
        update: update.bind(this)
      },
      true // Set background color transparent
    );

    function preload() {
      //load all house models
      this.game.load.spritesheet('booth_1_bdy', BoothBody1);
      this.game.load.spritesheet('booth_1_roof', BoothRoof1);
      this.game.load.spritesheet('booth_2_bdy', BoothBody2);
      this.game.load.spritesheet('booth_2_roof', BoothRoof2);
      this.game.load.spritesheet('booth_3_bdy', BoothBody3);
      this.game.load.spritesheet('booth_3_roof', BoothRoof3);
      this.game.load.spritesheet('booth_4_bdy', BoothBody4);
      this.game.load.spritesheet('booth_4_roof', BoothRoof4);
      this.game.load.spritesheet('booth_5_bdy', BoothBody5);
      this.game.load.spritesheet('booth_5_roof', BoothRoof5);
      this.game.load.spritesheet('booth_6_bdy', BoothBody6);
      this.game.load.spritesheet('booth_6_roof', BoothRoof6);
      this.game.load.spritesheet('booth_7_bdy', BoothBody7);
      this.game.load.spritesheet('booth_7_roof', BoothRoof7);
      this.game.load.spritesheet('booth_8_bdy', BoothBody8);
      this.game.load.spritesheet('booth_8_roof', BoothRoof8);
      this.game.load.spritesheet('booth_9_bdy', BoothBody9);
      this.game.load.spritesheet('booth_9_roof', BoothRoof9);
      this.game.load.spritesheet('booth_10_bdy', BoothBody10);
      this.game.load.spritesheet('booth_10_roof', BoothRoof10);
      this.game.load.spritesheet('booth_11_bdy', BoothBody11);
      this.game.load.spritesheet('booth_11_roof', BoothRoof11);
      this.game.load.spritesheet('booth_12_bdy', BoothBody12);
      this.game.load.spritesheet('booth_12_roof', BoothRoof12);
      this.game.load.spritesheet('booth_13_bdy', BoothBody13);
      this.game.load.spritesheet('booth_13_roof', BoothRoof13);
      this.game.load.spritesheet('booth_14_bdy', BoothBody14);
      this.game.load.spritesheet('booth_14_roof', BoothRoof14);
      this.game.load.image('background', BackgroundImage);
      this.game.load.spritesheet('malebody1', Malebody1, 64, 100);
      this.game.load.spritesheet('malecap1', Malecap1, 64, 100);
      this.game.load.spritesheet('maleskin1', Maleskin1, 64, 100);
      this.game.load.spritesheet('malebody2', Malebody2, 64, 100);
      this.game.load.spritesheet('malecap2', Malecap2, 64, 100);
      this.game.load.spritesheet('maleskin2', Maleskin2, 64, 100);
      this.game.load.spritesheet('femalebody1', Femalebody1, 64, 100);
      this.game.load.spritesheet('femalecap1', Femalecap1, 64, 100);
      this.game.load.spritesheet('femaleskin1', Femaleskin1, 64, 100);
      this.game.load.spritesheet('femalebody2', Femalebody2, 64, 100);
      this.game.load.spritesheet('femalecap2', Femalecap2, 64, 100);
      this.game.load.spritesheet('femaleskin2', Femaleskin2, 64, 100);
    }

    function create() {
      console.log("Game created")
      this.game.stage.disableVisibilityChange = true;
      this.scalingFactor = this.gameHeight / this.game.cache.getImage('background').height;

      //will add all shops to the world
      this.addShopBodys();

      //add playergroup after creating shop bodys-> layering !
      this.playerGroup = this.game.add.group();

      //add players to the world
      for (const [uuid, player] of Object.entries(this.currentGame.players)) {
        //create a buyer/ seller
        if (player.role === 'buyer') {
          this.players[uuid] = new Buyer(player, this.playerGroup, this.game, this.gameWidth, this.gameHeight, this.scalingFactor);
        }
      };

      this.addAnimations();
      //add roofs after buyers -> layering !
      this.addShopRoofs();

      for (const [uuid, player] of Object.entries(this.currentGame.players)) {
        if (player.role === 'buyer') {
          let animation = this.randomAnimation();
          this.playAnimation(uuid, animation.rnd1, animation.scalingprefix * this.scalingFactor);
        }
        if (player.role === 'seller') {
          this.spawnSellerAndColorRoof(player, this.shopIndexes.pop(), uuid);
        }
      };
    }

    function update() {
      this.frameCounter++;
      const game = this.props.getGameData();
      //if round has changed -> move back to spawn
      if (this.round !== game.currentRound) {
        this.round = game.currentRound;
        for (const uuid of Object.keys(this.players)) {
          this.resetPlayerPosition(uuid, this.game);
        };
      }

      for (const [uuid, player] of Object.entries(game.players)) {
        //THIS WILL MAKE THE PLAYER FACE THE RIGHT DIRECTION
        if (player.role === 'buyer') {
          //after 3 frames calculate angle to new position
          if (this.frameCounter > 3) {
            this.checkAngle(uuid);
            this.players[uuid].ref.xCoord = this.players[uuid].ref.body.x;
            this.players[uuid].ref.yCoord = this.players[uuid].ref.body.y;
          }

          /* THIS WILL MAKE THE PLAYER MOVE WHEN HE TRADED */
          //if rounds length changed -> player has traded or round is over
          if (player.rounds.length === game.currentRound && this.players[uuid].canTrade && player.tradepartner) {
            this.players[uuid].canTrade = false;
            this.moveTo(uuid, this.sellers[player.tradepartner]);
          }
        }
      };
    }
  }

  addAnimations = () => {
    this.playerGroup.callAll('animations.add', 'animations', 'idlewalkUp', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 8, 19, 20, 21, 22], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'idlewalkUpLeft', [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'idlewalkLeft', [47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'idlewalkDownLeft', [71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'idlewalkDown', [95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'walkUp', [120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'walkUpLeft', [143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 166], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'walkLeft', [167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'walkDownLeft', [191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 207, 208, 209, 210, 211, 212, 213, 214], 40, true);
    //-------------------------------------------------------------------------------------------
    this.playerGroup.callAll('animations.add', 'animations', 'walkDown', [215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239], 40, true);
  }

  spawnSellerAndColorRoof = (player, shopIndex, uuid) => {

    let bodyparts = [player.gender + 'body', player.gender + 'skin', player.gender + 'cap'];
    // color roof
    // add seller to shop as child
    if (!this.players[uuid]) {
      this.players[uuid] = {};
      this.players[uuid].ref = {};
    }
    let isDoubleShop = this.shopIndexes.indexOf(shopIndex) !== -1;
    let sellerPositionIndex = isDoubleShop ? (shopIndex === 3 ? 14 : 15) : shopIndex;
    for (const bodypart of Object.values(bodyparts)) {
      let keybodypart = bodypart.replace(/male|female/, '');

      this.players[uuid].ref[keybodypart] = this.game.add.sprite(this.sellerPositionOffset[sellerPositionIndex].x, this.sellerPositionOffset[sellerPositionIndex].y, bodypart + this.getPropNumber(player, keybodypart), 119);
      if (keybodypart === 'body') {
        this.players[uuid].ref[keybodypart].tint = '0x' + player.colors[1];
      }
      if (keybodypart === 'cap') {
        this.players[uuid].ref[keybodypart].tint = '0x' + player.colors[0];
      }
      this.players[uuid].ref[keybodypart].animations.add('idlewalkDown', [95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119], 40, true);
      this.players[uuid].ref[keybodypart].animations.add('idlewalkLeft', [47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70], 40, true);
      this.players[uuid].ref[keybodypart].animations.add('idlewalkUp', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 8, 19, 20, 21, 22], 40, true);
      this.players[uuid].ref[keybodypart].animations.add('idlewalkDownLeft', [71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94], 40, true);
      this.players[uuid].ref[keybodypart].animations.add('idlewalkUpLeft', [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46], 40, true);
      this.players[uuid].ref[keybodypart].animations.currentAnim = "";

    };
    //replace roof when seller is in shop -> layering
    this.allShops[shopIndex].roof = this.game.add.sprite(this.gameWidth * this.shopPosition[shopIndex].x, this.gameHeight * this.shopPosition[shopIndex].y, 'booth_' + (shopIndex + 1) + '_roof');
    this.allShops[shopIndex].roof.scale.setTo(this.scalingFactor, this.scalingFactor);
    this.allShops[shopIndex].roof.tint = '0x' + player.colors[0];

    this.sellers[uuid] = shopIndex;
    this.playAnimation(uuid, this.sellerPositionOffset[sellerPositionIndex].facing, this.sellerPositionOffset[sellerPositionIndex].scaling * this.scalingFactor);
  }

  checkAngle = (uuid) => {
    let pointA = new window.Phaser.Point(this.players[uuid].ref.xCoord, this.players[uuid].ref.yCoord);
    let angle = pointA.angle({ x: this.players[uuid].ref.body.x, y: this.players[uuid].ref.body.y }, true);

    if (angle < 0) {
      angle = 360 + angle;
    }

    if ((angle > 337.5 || angle <= 22.5) && angle !== 0) {
      //walk right
      this.playAnimation(uuid, 'walkLeft', -1 * this.scalingFactor);
    }
    else if (angle > 22.5 && angle <= 67.5) {
      //walk down right
      this.playAnimation(uuid, 'walkDownLeft', -1 * this.scalingFactor);
    }
    else if (angle > 67.5 && angle <= 112.5) {
      //walk down
      this.playAnimation(uuid, 'walkDown', this.scalingFactor);
    }
    else if (angle > 112.5 && angle <= 157.5) {
      //walk down left
      this.playAnimation(uuid, 'walkDownLeft', this.scalingFactor);
    }
    else if (angle > 157.5 && angle <= 202.5) {
      //walk left
      this.playAnimation(uuid, 'walkLeft', this.scalingFactor);
    }
    else if (angle > 202.5 && angle <= 247.5) {
      //walk up left
      this.playAnimation(uuid, 'walkUpLeft', this.scalingFactor);
    }
    else if (angle > 247.5 && angle <= 292.5) {
      //walk up
      this.playAnimation(uuid, 'walkUp', this.scalingFactor);
    }
    else if (angle > 292.5 && angle <= 337.5) {
      //walk down right
      this.playAnimation(uuid, 'walkUpLeft', -1 * this.scalingFactor);
    }
  }

  //add shop bodys to game world
  addShopBodys = () => {
    for (let i = 0; i < 14; i++) {
      this.allShops[i] = {};
      this.allShops[i].bdy = this.game.add.sprite(this.gameWidth * this.shopPosition[i].x, this.gameHeight * this.shopPosition[i].y, 'booth_' + (i + 1) + '_bdy');
      this.allShops[i].bdy.scale.setTo(this.scalingFactor, this.scalingFactor);
    }
  }

  //add roofs to buildings
  addShopRoofs = () => {
    for (let i = 0; i < 14; i++) {
      this.allShops[i].roof = this.game.add.sprite(this.gameWidth * this.shopPosition[i].x, this.gameHeight * this.shopPosition[i].y, 'booth_' + (i + 1) + '_roof');
      this.allShops[i].roof.scale.setTo(this.scalingFactor, this.scalingFactor);
    }
  }

  moveTo = (uuid, pathid) => {
    let playerBodyParts = [];
    playerBodyParts[0] = this.players[uuid].ref.body;
    playerBodyParts[1] = this.players[uuid].ref.skin;
    playerBodyParts[2] = this.players[uuid].ref.cap;

    let destinations = this.allPaths[pathid];

    for (let i = 0; i < playerBodyParts.length; i++) {
      let prevTween = null;
      let firstTween = null;
      let prevdestx = this.players[uuid].ref.body.x / this.gameWidth;
      let prevdesty = this.players[uuid].ref.body.y / this.gameHeight;
      for (let j = 0; j < destinations.x.length; j++) {
        // calc distance for duration
        let distance = window.Phaser.Point.distance({ x: destinations.x[j], y: destinations.y[j] }, { x: prevdestx, y: prevdesty });

        // create tween
        let currentTween = this.createTween(playerBodyParts[i], destinations.x[j], destinations.y[j], this.gameWidth, this.gameHeight, distance * 18000);
        if ((destinations.x.length - j) === 1) {
          currentTween.onComplete.add(() => {
            //ADD FUNCTION HERE WHERE SELLER AND BUYER FACE EACHOTHER
            //timeout -> angle function keeps firing
            setTimeout(() => {
              this.playAnimation(uuid, this.allPaths[pathid].face, this.allPaths[pathid].scale * this.scalingFactor);
            }, 100);
          }, this);

        }
        // if prevTweeen -> chain
        if (prevTween) {
          // chain
          prevTween.chain(currentTween);
        } else {
          //else -> this is the first tween -> there is no prev tween
          firstTween = currentTween;
        }
        // set prevTween
        prevTween = currentTween;
        prevdestx = destinations.x[j];
        prevdesty = destinations.y[j];
      }
      firstTween.start();
    }
  }

  createTween = (playerBodyPart, x, y, gameWidth, gameHeight, duration) => {
    let t = this.game.add.tween(playerBodyPart).to({
      x: x * gameWidth,
      y: y * gameHeight
    }, duration, 'Linear', false, 0).interpolation((v, k) => {
      return window.Phaser.Math.bezierInterpolation(v, k);
    });

    return t;
  }

  resetPlayerPosition = (uuid) => {
    if (this.players[uuid].ref.role === 'buyer') {
      let randomize = Math.floor(Math.random() * 250) + 1,
        randomize2 = Math.floor(Math.random() * 130) + 1;
      this.players[uuid].tradepartner = undefined;
      this.players[uuid].canTrade = true;

      for (const bodypart of Object.values(this.extremities)) {
        this.players[uuid].ref[bodypart].x = this.gameWidth * 0.45 + randomize;
        this.players[uuid].ref[bodypart].y = this.gameHeight * 0.3 + randomize2;
      };

      setTimeout(() => {
        let animation = this.randomAnimation();
        this.playAnimation(uuid, animation.rnd1, animation.scalingprefix * this.scalingFactor);

      }, 50);
    }
  }

  //4 values -> uuid , x and y (if value -1 then moving straight and other variable will be ignored), scale for left/right, animationType to set the animation
  playAnimation = (uuid, animationType, scale) => {
    let model = this.players[uuid].ref ? this.players[uuid].ref : this.players[uuid];
    if (model.body.animations.currentAnim.name !== animationType) {
      model.body.animations.play(animationType);
      model.skin.animations.play(animationType);
      model.cap.animations.play(animationType);
    }
    //increase models by 10%
    model.body.scale.setTo(scale * 1.1, this.scalingFactor * 1.1);
    model.skin.scale.setTo(scale * 1.1, this.scalingFactor * 1.1);
    model.cap.scale.setTo(scale * 1.1, this.scalingFactor * 1.1);

  }

  randomAnimation = () => {
    let rnd1 = Math.floor(Math.random() * 5) + 1;
    let rnd2 = Math.floor(Math.random() * 2) + 1;
    let scalingprefix;

    switch (rnd1) {
      case 1: rnd1 = 'idlewalkUp';
        break;
      case 2: rnd1 = 'idlewalkUpLeft';
        break;
      case 3: rnd1 = 'idlewalkLeft';
        break;
      case 4: rnd1 = 'idlewalkDownLeft';
        break;
      case 5: rnd1 = 'idlewalkDown';
        break;
      default:
        break;
    }
    if (rnd2 === 1) {
      scalingprefix = -1;
    } else {
      scalingprefix = 1;
    }
    return { rnd1: rnd1, scalingprefix: scalingprefix };
  }

  getPropNumber = (player, bodypart) => {
    if (bodypart === 'body') {
      return player.clothprop;
    } else if (bodypart === 'skin') {
      return player.skinprop;
    } else if (bodypart === 'cap') {
      return player.headprop;
    }
  }

  shuffle = (a) => {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  render() {
    return <div id="phaser-container-market" ></div>;
  }
}

export default MarketAnimation;