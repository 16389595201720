import React from 'react';
import { useStores } from '../../hooks';

function PresentationButton() {
  const { adminStore } = useStores();

  function handleTogglePresentation(event) {
    const type = (adminStore.rootStore.gameStore.game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'Projection', 'Start / stop projection window']);

    event.preventDefault();
    adminStore.togglePresentation();
  }

  return (
    <p>
      <button onClick={handleTogglePresentation} className="btn btn-success" type="button">
        <i className="fa fa-desktop"></i>Projektion an-/ausschalten
      </button>
    </p>
  );
}

export default PresentationButton;
