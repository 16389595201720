import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from '../hooks';

const ErrorToast = observer(() => {
  const { gameStore: { showErrorToast } } = useStores();

  return (
    <div className={`position-absolute hidden w-100 d-flex ${showErrorToast ? "visible" : "invisible"} justify-content-center mt-1 toast-wrapper`} style={{ opacity: showErrorToast ? 1 : 0, zIndex: 2000 }}>
      <div className=" d-flex justify-content-center align-items-center rounded min-vw-50 px-3 bg-danger text-white shadow-sm" style={{ height: "40px" }}>
        <p className="m-0">Etwas ist schiefgelaufen.</p>
      </div>
    </div>
  )
})

export default ErrorToast;


