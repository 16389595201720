import React from 'react';
import { useHistory } from 'react-router-dom'
import { BounceLoader } from 'react-spinners';
import { useStores } from '../hooks';

function Loader() {
  const { gameStore } = useStores();
  const history = useHistory();

  function handleResetData() {
    gameStore.deleteCurrent();
    history.push("/");
  }

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <BounceLoader size={60} color="#5a145a" />
      <p className="mt-5 text-muted" onClick={handleResetData}><u>Zurück zur Startseite!</u></p>
    </div>
  )
}

export default Loader;


