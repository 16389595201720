import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../components/player/avatar';

function FisheryIntro({ game, currentPlayer }) {
  const [index, setIndex] = useState(0);
  const [finished, setFinished] = useState(false);
  const history = useHistory();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    if (selectedIndex === 3) setFinished(true);
  };

  function handleGoToLobby() {
    history.push('/lobby');
  }

  return (
    <div className="container-fluid">
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null} slide={false} fade={false}>
        <Carousel.Item>
          <div className="d-block"><Avatar game={game} currentPlayer={currentPlayer} index={0} /></div>
          <Carousel.Caption>
            <h5>Auf hoher See</h5>
            <p>
              Jede Gruppe steuert als Bootsbesatzung eins von 5 Fischerbooten,
              die alle in der gleichen Bucht Fische fangen. Der Fischfang ist
              eure einzige Einnahmequelle.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-group text-info"></i>
          </div>

          <Carousel.Caption>
            <h5>Euer Fischfang</h5>
            <p>
              Entscheidet in eurer Gruppe zusammen, wie viele Fische ihr fangen
              wollt. Gebt in jeder Runde eure gewünschte Fangmenge in Tonnen
              ein.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-line-chart text-info"></i>
          </div>
          <Carousel.Caption>
            <h5>Der Fischbestand</h5>
            <p>
              Nach jeder Fangsaison (Runde) erholt sich der Fischbestand in der
              Schonzeit wieder.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-block">
            <br />
            <i className="fa fa-4x fa-balance-scale text-warning"></i>
          </div>
          <Carousel.Caption>
            <h5>Die Fangmenge</h5>
            <p>
              {Math.floor(
                (game.config.fishAmount * game.config.catchQuota) / 100
              )}
              Euer Boot kann maximal 100 Tonnen pro Runde fischen.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {finished ? (
        <div className="py-4 text-center">
          <button onClick={handleGoToLobby} className="btn btn-lg btn-primary" type="button">
            OK, verstanden
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default FisheryIntro;
