import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../hooks/index';
import Countdown from '../../../components/countdown';
import CurrentRound from '../../../components/currentRound';
import PresentationButton from '../../../components/buttons/presentationButton';
import EndButton from '../../../components/buttons/endButton';
import Loader from '../../../components/loader';
import AbortButton from '../../../components/buttons/abortButton';
import Header from '../../../components/header';
import FisheryIngame from './fishery';
import MarketIngame from './market';

const Running = observer(() => {
  const {
    gameStore: { game, cookie },
    adminStore,
  } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (cookie.value) history.push('/');
    else if (game && !game.status) history.push('/admin/dashboard');
    // else if (game && game.status !== 'running') history.push('/admin/result');
  })

  useEffect(() => {
    if (!game) return;
    let finishedRound = game.players && !!Object.keys(game.players).length;

    for (const [, player] of Object.entries(game.players)) {
      finishedRound =
        finishedRound && game.currentRound === player.rounds.length;
    }

    if (finishedRound && game.status === 'running') {
      adminStore.endRound();
      history.push('/admin/result');
    }
  }, [game, adminStore, history]);

  function handleEndRound() {
    const type = (game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'End round', `Manually ending round, Players ${game.playersSize}`]);

    adminStore.endRound();
    history.push('/admin/result');
    window._paq.push(['trackPageView', `Admin dashboard, ${game.gameType} game result screen after manually ending round`]);
  }

  return (
    game ?
      <div className="container-fluid">
        <Header game={game}></Header>
        <div className="d-flex justify-content-between">
          <div>
            <CurrentRound game={game} />
          </div>
          <Countdown />
        </div>
        <hr />
        <p>
          <span onClick={handleEndRound} className="btn btn-primary btn-lg">
            <i className="fa fa-forward"></i>Runde beenden
        </span>
        &nbsp;
        <i className="text-muted small">
            Runde wird automatisch beendet, sobald alle Spieler{' '}
            {game.gameType === 'market' ? 'handeln' : 'fischen'} oder die Zeit
          abläuft
        </i>
        </p>
        <PresentationButton />
        <hr />
        {game.gameType === 'market' ? <MarketIngame /> : <FisheryIngame />}
        <hr />
        <EndButton />
        <AbortButton />
      </div> : <Loader />
  )
});

export default Running;
