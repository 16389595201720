import React from 'react';
import { Link, useHistory } from 'react-router-dom';

function Footer({ showHome }) {
  const history = useHistory();

  function handleGoBack() {
    history.goBack();
  }

  return (
    <footer className="container-fluid mt-5 small">
      <hr />
      <div className="row">
        <div className="col-8">© Joachim Herz Stiftung</div>
        <div className="text-right col-4">
          {
            showHome ? <span className="text-info" style={{ cursor: 'pointer' }} onClick={handleGoBack}>Zurück</span> : <Link to="/impressum">Impressum</Link>
          }
        </div>
      </div>
    </footer>
  )
}

export default Footer;


