import './App.scss';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PlayerJoinPage from './pages/game/join';
import PlayerIntroPage from './pages/game/intro';
import PlayerLobbyPage from './pages/game/lobby';

import PresentationNew from './pages/presentation/new';
import PresentationRunning from './pages/presentation/running';

import AdminNewGamePage from './pages/admin/new';
import AdminDashboardPage from './pages/admin/dashboard';
import AdminGamePage from './pages/admin/game';
import AdminResultPage from './pages/admin/result';

import ImpressumPage from './pages/impressum';
import NotFoundPage from './pages/notFound';

import ErrorToast from './components/errorToast';

function App() {
  return (
    <Router hashType="hashbang">
      <ErrorToast />
      <Switch>
        {/* Admin */}
        <Route path="/admin/dashboard">
          <AdminDashboardPage />
        </Route>
        <Route path="/admin/result">
          <AdminResultPage />
        </Route>
        <Route path="/admin/game">
          <AdminGamePage />
        </Route>
        <Route path="/admin">
          <AdminNewGamePage />
        </Route>
        {/* Presentation */}
        <Route path="/presentation/new">
          <PresentationNew />
        </Route>
        <Route path="/presentation/running">
          <PresentationRunning />
        </Route>
        {/* Player */}
        <Route path="/lobby">
          <PlayerLobbyPage />
        </Route>
        <Route path="/intro">
          <PlayerIntroPage />
        </Route>
        <Route path="/impressum">
          <ImpressumPage />
        </Route>
        <Route>
          <PlayerJoinPage />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
