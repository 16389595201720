import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useStores } from '../../../hooks';
import Results from '../../../components/result';
import CurrentRound from '../../../components/currentRound';
import Loader from '../../../components/loader';
import EndText from '../../../components/endText';
import Countdown from '../../../components/countdown';
import FisheryAnimation from './fishery';
import Poster from '../../../assets/images/bg/transparent.png';
import OceanMP4 from '../../../assets/images/bg/ocean.mp4';
import OceanWEBM from '../../../assets/images/bg/ocean.webm';
import MarketAnimation from './market';

const PresentationLayout = observer(() => {
  const { gameStore: { game, getDataForAnimation }, presentationStore } = useStores();
  const history = useHistory();

  function handleToggleFullScreen() {
    presentationStore.toggleFullscreen();
  }

  function handleMouseMove() {
    presentationStore.mouseMoved();
  }

  useEffect(() => {
    if (game && !game.status) history.push("/presentation/new");
    else if (game && game.status === 'running') presentationStore.resetResultModal();
  })

  return (
    game ?
      <>
        <div onMouseMove={handleMouseMove}>
          <div className="overlayed">
            <div className="row">
              <div className="col">
                <CurrentRound game={game} />
              </div>
              <div className="col text-center">
                {
                  game.status === 'running' ? <Countdown playSound hideActions /> : null
                }
              </div>
              <div className="col text-right">
                <span
                  onClick={handleToggleFullScreen}
                  className="text-secondary"
                >
                  <i className="fa fa-arrows-alt"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            id="ph_game"
            className={game.gameType}
          >
            {
              game.gameType === "fishery" ? <FisheryAnimation game={game} getGameData={getDataForAnimation} /> : <MarketAnimation game={game} getGameData={getDataForAnimation} />
            }
          </div>

          {
            game.gameType === 'fishery' ? (
              <video
                poster={Poster}
                autoPlay={true}
                loop
              >
                <source src={OceanMP4} type="video/mp4" />
                <source src={OceanWEBM} type="video/webm" />
              </video>) : null
          }

        </div>

        <Modal
          show={presentationStore.showResultModal}
          backdrop="static"
          keyboard={false}
          animation={false}

        >
          <Modal.Body>
            {
              game.status === 'finished' && <h2 className="float-right">Das Spiel ist zu Ende!</h2>
            }
            {
              (game.status === 'ended' || game.status === 'finished') && <Results displayType="presentation" game={game} />
            }
          </Modal.Body>
        </Modal>

        <Modal
          show={presentationStore.showEndModal}
          backdrop="static"
          keyboard={false}>
          <Modal.Body className="modal-body">
            <EndText game={game} />
          </Modal.Body>
        </Modal>
      </> : <Loader />
  );
})

export default PresentationLayout;
