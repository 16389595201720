const Buyer = function (player, playerGroup, game, gameWidth, gameHeight, scalingFactor) {
  let obj = this.ref = {};
  let randomize = Math.floor(Math.random() * 150) + 1,
    randomize2 = Math.floor(Math.random() * 100) + 1,
    x = gameWidth * 0.45 + randomize,
    y = gameHeight * 0.3 + randomize2;
  let bodyparts = ['body', 'skin', 'cap'];

  for (const bodypart of Object.values(bodyparts)) {
    obj[bodypart] = {};
    obj[bodypart] = playerGroup.create(x, y, player.gender + bodypart + getPropNumber(player, bodypart));
    obj[bodypart].anchor.set(0.5);
    obj[bodypart].scale.setTo(scalingFactor, scalingFactor);
  };
  obj.cap.tint = '0x' + player.colors[1];
  obj.body.tint = '0x' + player.colors[0];
  obj.tradepartner = player.tradepartner;

  obj.xCoord = obj.body.x;
  obj.yCoord = obj.body.y;
  obj.role = 'buyer';

  this.canTrade = true;
};

function getPropNumber(player, bodypart) {
  if (bodypart === 'body') {
    return player.clothprop;
  } else if (bodypart === 'skin') {
    return player.skinprop;
  } else if (bodypart === 'cap') {
    return player.headprop;
  }
}

export default Buyer;