import React from 'react';

function CurrentRound({ game }) {
  const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'];

  return (
    <>
      <b>Runde: </b>
      {game.currentRound} / {game.config.roundAmount}
      {game.gameType === 'market'
        ? ' - ' + dayNames[game.currentRound - 1]
        : ''}
    </>
  );
}

export default CurrentRound;
