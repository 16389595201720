import React from 'react';
import PlayerName from '../../../components/player/playerName';
import { useStores } from '../../../hooks';
import { observer } from 'mobx-react';

const DashboardFishery = observer(() => {
  const {
    adminStore,
    gameStore,
    gameStore: { game },
  } = useStores();

  function handleRemovePlayer(uuid) {
    adminStore.removePlayer(uuid);
  }

  function handleChangeRoundDuraction(event) {
    event.preventDefault();
    gameStore.updateGameConfig('roundDuration', event.target.value);
  }

  function handleChangeRoundAmount(event) {
    event.preventDefault();
    gameStore.updateGameConfig('roundAmount', event.target.value);
  }

  function renderPlayer() {
    const players = game.players;
    const openSpots = game.playersSize ? 5 - game.playersSize : 5;
    const list = [];

    if (game.players) {
      for (const [uuid, player] of Object.entries(players)) {
        list.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={uuid}
          >
            <span>
              Boot &nbsp; <PlayerName player={player} />
            </span>
            <span>
              <span className="badge badge-success badge-pill">Bereit</span>
              <button
                onClick={() => handleRemovePlayer(uuid)}
                type="button"
                className="ml-3 close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
          </li>
        );
      }
    }

    for (var i = 0; i < openSpots; i++) {
      list.push(
        <li
          className="list-group-item d-flex justify-content-between align-items-center"
          key={i}
        >
          Boot {game.playersSize + i + 1}
          <span className="badge badge-info badge-pill">Nicht bereit</span>
        </li>
      );
    }
    return list;
  }

  return (
    <div className="row">
      <div className="col">
        <div className="form-group row">
          <label htmlFor="roundDuration" className="col-3 col-form-label">
            Rundenzeit
          </label>
          <div className="col-3">
            <input
              type="number"
              className="form-control"
              id="roundDuration"
              value={game.config?.roundDuration}
              onChange={handleChangeRoundDuraction}
              min="1"
              max="10"
              required
              autoComplete="off"
              autoCorrect="off"
            />
          </div>
          <div className="pl-0 text-left col btn">Minuten</div>
        </div>

        <div className="form-group row">
          <label htmlFor="roundAmount" className="col-3 col-form-label">
            Rundenanzahl
          </label>
          <div className="col-3">
            <input
              type="number"
              className="form-control"
              id="roundAmount"
              value={game.config?.roundAmount}
              onChange={handleChangeRoundAmount}
              min="5"
              max="9"
              required
              autoComplete="off"
              autoCorrect="off"
            />
          </div>
        </div>
      </div>

      <div className="col">
        <ul className="list-group">{renderPlayer()}</ul>
      </div>
    </div>
  );
});

export default DashboardFishery;
