import React from 'react';
import { useStores } from '../../hooks';
import Footer from '../../components/footer';

function Impressum() {
  const { gameStore } = useStores();

  return (
    <>
      <div className="container-fluid">
        <header>
          <h1>Impressum</h1>
        </header>
        <p>
          <b>Herausgeber</b>
          <br />
        Joachim Herz Stiftung
        <br />
        Langenhorner Chaussee 384
        <br />
        22419 Hamburg
        <br />
          <a href="https://www.joachim-herz-stiftung.de/">
            www.joachim-herz-stiftung.de
        </a>
        </p>
        <p>
          © Joachim Herz Stiftung, 2018
        <br />
        Alle Rechte vorbehalten.
      </p>

        <p>
          <b>Produktion</b>
          <br />
        Gamify now! UG
      </p>

        <p>
          <b>Programmierung und Design</b>
          <br />
        REDOX GmbH
      </p>

        <p>
          <b>Redaktion</b>
          <br />
        Melanie Ziegler, C.C. Buchner Verlag
      </p>

        <p>
          <b>Fachberatung</b>
          <br />
        Christian Rapp
      </p>

        <div hidden={gameStore.game?.gameType === 'fishery'}>
          <p>
            <b>Spielidee Marktspiel</b>
            <br />
          Idee nach: Kristine Gebhardt: Das Modellspiel „Preisbildung auf dem
          Apfelmarkt“. In: Heinz Jacobs (Hrsg.): Ökonomie spielerisch lernen.
          Schwalbach/Ts. 2010, S. 66 ff.
        </p>
        </div>

        <div hidden={gameStore.game?.gameType === 'market'}>
          <p>
            <b>Spielidee Fischerspiel</b>
            <br />
          Idee nach: Wolfgang Ziefle, Das Fischerspiel. In: Landeszentrale für
          politische Bildung Baden-Württemberg, Politik und Unterricht, 1/1995,
          S. 7ff.
        </p>
        </div>

      </div><Footer showHome /> </>
  );
}

export default Impressum;
