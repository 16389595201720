import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../hooks';
import Loader from '../../../components/loader';
import IntroFishery from './fishery';
import IntroMarket from './market';
import { useHistory } from 'react-router';

const IntroPage = observer(() => {
  const {
    gameStore: { game, currentPlayer },
  } = useStores();
  const history = useHistory()

  useEffect(() => {
    if (!game) {
      history.push('/');
    } else if (game && currentPlayer && game.status) {
      history.push('/lobby')
    }
  }, [game, currentPlayer, history])

  return game && currentPlayer ?
    <div className={`playing ${game.gameType}`}>
      {game.gameType === 'fishery' ?
        <IntroFishery game={game} currentPlayer={currentPlayer} /> :
        <IntroMarket game={game} currentPlayer={currentPlayer} />
      }
    </div> : <Loader />;
});

export default IntroPage;
