import React from 'react';

function SellerComponent({ player }) {
  return (
    <>
      <p>
        Du willst eine Kiste Äpfel verkaufen.
        <br />
        Überlege dir, welchen Preis du verlangen willst.
      </p>

      <p>
        Deine Kosten betragen <b>{player.money} Euro</b>.
      </p>

      <p className="text-primary font-weight-bold">
        Gewinn = Verkaufspreis - Kosten
      </p>

      <p>
        Finde in der Klasse einen Käufer für deine Apfelkiste und handle mit dem
        Kunden den Preis aus.
      </p>
    </>
  );
}

export default SellerComponent;
