import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import RoleName from '../../../components/player/roleName';
import { Modal } from 'react-bootstrap';
import UtilsService from '../../../services/utils'

const MarketModal = observer(({ resetNumber }) => {
  const {
    gameStore: { currentPlayer: player },
    playingStore: { payload, showModal, partner, accepted },
    playingStore,
  } = useStores();

  const [underLimitError, setUnderLimitError] = useState(false);
  const [overLimitError, setOverLimitError] = useState(false);
  const [price, setPrice] = useState(0);

  function handleTradeSubmit(event) {
    event.preventDefault();
    playingStore.tradeSubmit();
  }

  function handleTradeAccept() {
    playingStore.acceptIncomingTradePartner(partner, { actionName: 'Trade:PartnerAccepted' });
  }

  function handlePriceChange(event) {
    setOverLimitError(false);
    setUnderLimitError(false);

    const value = event.target.value;
    setPrice(UtilsService.formatValueToString(value));
    if (!value) return;

    let formatedPrice = UtilsService.formatValueToPrice(value);
    if (player.role === 'buyer' && formatedPrice > player.money) {
      setOverLimitError(true);
    } else if (player.role === 'seller' && formatedPrice < player.money) {
      setUnderLimitError(true);
    }
    playingStore.updatePrice(formatedPrice);
  }

  function handleCloseModal() {
    playingStore.deleteTradePartner();
    resetNumber('');
  }

  function formatPartnerPrice(price) {
    if (price) return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(price);

    else return "0,00"
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal} animation={false} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <form name="form" hidden={!partner} onSubmit={handleTradeSubmit}>
          {
            accepted ? (
              <div>
                <h2>
                  Handeln mit <RoleName player={partner} />
                </h2>

                <div className="form-group">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="text-right btn px-0 m-0">
                      Angebot <br className="d-block d-sm-none" />
                      <RoleName player={player} opposite genitive />:
                    </label>
                    <div>
                      <div className="text-left d-flex align-items-center">
                        {formatPartnerPrice(partner.price)}
                        <span className="ml-3">€</span>
                        {partner.confirmTrade ? (
                          <i className="fa fa-check text-success ml-2 mr-3"></i>
                        ) : (
                          <i className="fa fa-check text-white ml-2 mr-3"></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="price" className="text-right btn px-0 m-0">
                      Mein Angebot:
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className={`form-control text-right mx-2 ${underLimitError || overLimitError ? 'is-invalid' : ''}`}
                        id="price"
                        name="price"
                        value={price}
                        onChange={handlePriceChange}
                        required
                        maxLength={price >= 10 ? 5 : 4}
                        autoComplete="off"
                        autoCorrect="off"
                      />
                      <div className="text-left d-flex align-items-center">
                        €
                        {payload.confirmTrade ? (
                          <i className="fa fa-check text-success ml-2 mr-3"></i>
                        ) : (
                          <i className="fa fa-check text-white ml-2 mr-3"></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={
                    overLimitError ||
                    underLimitError ||
                    parseFloat(payload.price) !== parseFloat(partner.price)
                  }
                >
                  Handeln
                </button>
                <span className="btn text-secondary" onClick={handleCloseModal}>
                  Abbrechen
                </span>
                <br />
                <div
                  hidden={
                    !(
                      payload.price > 0 &&
                      partner.price > 0 &&
                      payload.price !== partner.price
                    )
                  }
                  className="form-text text-muted mt-2"
                >
                  Kein Handel möglich, Beträge müssen gleich sein!
                </div>
                {underLimitError && (
                  <div hidden={!price} className="form-text text-danger mt-2">
                    <span>Deine Kosten müssen gedeckt sein!</span>
                  </div>
                )}
                {overLimitError && (
                  <div hidden={!price} className="form-text text-danger mt-2">
                    <span>Du kannst nicht mehr als dein Budget ausgeben!</span>
                  </div>
                )}
                {payload.confirmTrade && (
                  <div className="form-text text-muted mt-2">
                    Warte auf Bestätigung!
                  </div>
                )}
              </div>
            ) : (
              <div hidden={accepted} className="py-5">
                <div
                  hidden={partner.waitForAccept}
                  className="py-5 w-100 text-center"
                >
                  <p className="lead">
                    <RoleName player={partner} /> will mit dir handeln!
                  </p>
                  <span
                    onClick={handleTradeAccept}
                    className="btn btn-primary mr-2"
                  >
                    Akzeptieren
                  </span>
                  <span
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                  >
                    Ablehnen
                  </span>
                </div>
                <div
                  hidden={!partner.waitForAccept}
                  className="py-5 w-100 text-center"
                >
                  <p className="lead">
                    Warte auf Antwort
                    <br />
                    von <RoleName player={partner} />!
                  </p>
                </div>
              </div>
            )
          }
        </form>
      </Modal.Body>
      <Modal.Footer hidden={!accepted}>
        Dein
        {player.role === 'seller' ? 'e Kosten betragen' : ' Budget beträgt'}
        &nbsp;
        <b>{player.money} €</b>
      </Modal.Footer>
    </Modal>
  );
});

export default MarketModal;
