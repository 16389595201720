import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import Footer from '../../../components/footer';

const GameJoin = observer(() => {
  const [code, setCode] = useState('');
  const [wrongCode, setWrongCodeWarning] = useState(false);
  const {
    gameStore,
    gameStore: { game, currentPlayer, cookie },
  } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (game && !game.status && !cookie.value) {
      history.push('/admin');
    } else if (game && !game.status) {
      history.push('/intro');
    } else if (game && currentPlayer) {
      history.push('/lobby')
    }
  }, [cookie.value, currentPlayer, game, history]);

  function handleCodeSubmit(event) {
    event.preventDefault();
    gameStore.connectPlayer(code).catch((err) => {
      setWrongCodeWarning(true);
    });
  }

  function handleCodeInput(event) {
    if (wrongCode) setWrongCodeWarning(false);
    setCode(event.target.value);
  }

  return (
    <>
      <div className="container-fluid">
        <header>
          <h1>Verbinde dich mit dem Spiel</h1>
        </header>

        <form
          name="form"
          onSubmit={handleCodeSubmit}
          className="m-auto text-center col-md-3"
        >
          <span className="text-muted">Spielcode</span>
          <input
            className="text-center form-control form-control-lg"
            onChange={handleCodeInput}
            value={code}
            type="number"
            required
            autoComplete="off"
            autoCorrect="off"
          />
          <br />
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            disabled={!code}
          >
            Mitspielen
          </button>
          <div hidden={!wrongCode} className="text-muted mt-3">
            Spiel wurde nicht gefunden, probiere einen anderen Code!
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
});

export default GameJoin;
