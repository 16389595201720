class UtilsService {
  formatValueToPrice(value) {
    let stringValue;
    let floatValue;
    if (value) {
      stringValue = this.formatValueToString(value);
      floatValue = parseFloat(stringValue.replace(',', '.')).toFixed(2); // sets right string, with 2 decimal places
      floatValue = parseFloat(floatValue);
    }
    return floatValue;
  }

  formatValueToString(value) {
    let stringValue;
    if (value && value.length) {
      stringValue = value.replace(/[^\d.,]/g, ''); // filters out unallowed values
      stringValue = stringValue.replace(/(\d+[.,]\d)(\d.*)$/, '$10'); // sets right string, with 2 decimal places and no 0,01
      stringValue = stringValue.replace('.', ',');
    }
    return stringValue;
  }

  formatNumber(number = 0) {
    return number.toLocaleString('de-De', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: false,
    });
  }
}

export default new UtilsService();
