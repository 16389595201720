import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import Avatar from '../../../components/player/avatar';
import PlayerName from '../../../components/player/playerName';

const FisheryLobby = observer(() => {
  const { gameStore: { game, currentPlayer }, gameStore, playingStore } = useStores();
  const player = currentPlayer;
  const [catchAmount, setCatchAmount] = useState(0);

  function handleSubmit(event) {
    event.preventDefault();
    playingStore.submit({ catchAmount: catchAmount });
  }

  function handleChangeCatchAmount(event) {
    const amount = event.target.value;
    setCatchAmount(amount);
  }

  const max = Math.floor(
    (game.config.fishAmount * game.config.catchQuota) / 100
  );

  return (
    <div className="container-fluid">
      <header>
        <div className="d-flex align-items-center justify-content-center">
          <Avatar game={game} currentPlayer={currentPlayer} index={0} />
          <div>
            <h1>
              <PlayerName player={player} />
            </h1>
            Bisher gefangen: {player.roundsTotal || 0}
            &nbsp;t
          </div>
        </div>
      </header>

      <main hidden={game.status === 'finished'}>
        <hr className="mt-0" />
        <h3 className="text-center">
          {!(
            game.currentRound > gameStore.currentPlayer.rounds.length
          ) ? (
            <span>
              Eure Fangmenge:{' '}
              {gameStore.currentPlayer.rounds[
                gameStore.currentPlayer.rounds.length - 1
              ] || 0}
              &nbsp;t
            </span>
          ) : (
            <span>Aktueller Fischbestand: {
              game.fishAmountBeginning.length
                ? game.fishAmountBeginning[game.currentRound - 1]
                : game.config.fishAmount}&nbsp;t
            </span>
          )}
        </h3>
        <hr />
        <form
          hidden={
            !(
              game.currentRound >
              gameStore.currentPlayer.rounds.length &&
              game.status === 'running'
            )
          }
          name="form"
          onSubmit={handleSubmit}
          className="m-auto text-center"
        >
          <span className="text-muted">Fangmenge in Tonnen</span>
          <input
            className="form-control form-control-auto mt-2"
            onChange={handleChangeCatchAmount}
            type="number"
            value={catchAmount}
            required
            min="0"
            max={max}
            name="amount"
            autoComplete="off"
            autoCorrect="off"
          />
          <br />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={catchAmount > max || catchAmount === 0}
          >
            <i className="fa fa-anchor"></i> Fischen
          </button>

          {catchAmount > max ? (
            <div className="form-text text-danger">
              <br />
              Dein Boot kann nicht so viele Fische fangen!
              <br />
              Maximum: <b>{max} t</b>
            </div>
          ) : null}
        </form>
      </main>

      <footer>
        <div className="card bg-light">
          <div className="card-body">
            <p>
              Besprich mit deinen Gruppenmitgliedern, wie viele Tonnen ihr
              fischen wollt.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
});

export default FisheryLobby;
