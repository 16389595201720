import React from 'react';

function EndText({ game }) {
  function renderText() {
    if (game.currentRound === game.roundAmount) {
      return (
        <p>
          Ihr habt alle Runden erfolgreich durchgespielt.
          <br />
          Das Spiel ist beendet. Es folgt die Auswertung.
        </p>
      );
    } else if (
      game.currentRound < game.roundAmount &&
      !(game.gameType === 'fishery' && game.fishAmount <= 0)
    ) {
      return <p>Das Spiel wurde abgebrochen! Es folgt die Auswertung.</p>;
    } else if (game.gameType === 'fishery' && game.fishAmount <= 0) {
      <p>Der Fischbestand erholt sich nicht mehr! Das Spiel ist beendet.</p>;
    }
  }

  return (
    <div hidden={game.status !== 'finished'} className="text-center lead">
      <hr />
      {renderText()}
      <hr />
    </div>
  );
}

export default EndText;
