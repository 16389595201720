import React, { Component } from 'react';
import BoatMasterSheet from '../../assets/images/boat_masterSheet.png';
import BoatHullSheet from '../../assets/images/boat_hullSheet.png';
import BoothBdy from '../../assets/images/booths/booth_6_bdy.png';
import BoothRoof from '../../assets/images/booths/booth_6_roof.png';
import MaleClothes1 from '../../assets/images/male01_clothes.png';
import MaleHead1 from '../../assets/images/male01_headprop01.png';
import MaleSkin1 from '../../assets/images/male01_skin01.png';
import MaleClothes2 from '../../assets/images/male02_clothes.png';
import MaleHead2 from '../../assets/images/male02_headprop01.png';
import MaleSkin2 from '../../assets/images/male02_skin01.png';
import FemaleClothes1 from '../../assets/images/female01_clothes.png';
import FemaleHead1 from '../../assets/images/female01_headprop01.png';
import FemaleSkin1 from '../../assets/images/female01_skin01.png';
import FemaleClothes2 from '../../assets/images/female02_clothes.png';
import FemaleHead2 from '../../assets/images/female02_headprop01.png';
import FemaleSkin2 from '../../assets/images/female02_skin01.png';

window.PIXI = require('phaser-ce/build/custom/pixi');
window.p2 = require('phaser-ce/build/custom/p2');
window.Phaser = require('phaser-ce/build/custom/phaser-split');

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.isFishery = props.game.gameType === 'fishery';
    this.isMarket = props.game.gameType === 'market';
    this.currentPlayer = props.currentPlayer;
    this.role = props.currentPlayer.role;
    this.avatarSize = 120;
    this.boatImgWidth = 330;
    this.boatImgHeight = 304;
    this.boothSize = 480;
    this.manWidth = 64;
    this.manHeight = 100;
  }

  shouldComponentUpdate() {
    return false; // componentDidUpdate not called because of this
  }
  componentWillUnmount() {
    this.game.destroy();
  }

  componentDidMount() {
    this.game = new window.Phaser.Game(
      this.avatarSize,
      this.avatarSize,
      window.Phaser.CANVAS,
      `phaser-container-${this.props.index}`,
      {
        preload: preload.bind(this),
        create: create.bind(this)
      },
      true
    );

    function preload() {
      if (this.isFishery) {
        this.game.load.spritesheet('boatImg', BoatMasterSheet, {
          frameWidth: this.boatImgWidth,
          frameHeight: this.boatImgHeight,
        });
        this.game.load.spritesheet('boatRecolorImg', BoatHullSheet, {
          frameWidth: this.boatImgWidth,
          frameHeight: this.boatImgHeight,
        });
      }
      if (this.isMarket) {
        var partnerRole = {
          seller: 'buyer',
          buyer: 'seller',
        };
        this.role = this.props.opposite ? partnerRole[this.role] : this.role;

        if (this.role === 'seller') {
          this.game.load.spritesheet('booth_bdy', BoothBdy);
          this.game.load.spritesheet('booth_roof', BoothRoof);
        } else {
          //malebody1
          this.game.load.spritesheet('malebody1', MaleClothes1, this.manWidth, this.manHeight);
          this.game.load.spritesheet('malecap1', MaleHead1, this.manWidth, this.manHeight);
          this.game.load.spritesheet('maleskin1', MaleSkin1, this.manWidth, this.manHeight);

          //malebody2
          this.game.load.spritesheet('malebody2', MaleClothes2, this.manWidth, this.manHeight);
          this.game.load.spritesheet('malecap2', MaleHead2, this.manWidth, this.manHeight);
          this.game.load.spritesheet('maleskin2', MaleSkin2, this.manWidth, this.manHeight);

          //femalebody1
          this.game.load.spritesheet('femalebody1', FemaleClothes1, this.manWidth, this.manHeight);
          this.game.load.spritesheet('femalecap1', FemaleHead1, this.manWidth, this.manHeight);
          this.game.load.spritesheet('femaleskin1', FemaleSkin1, this.manWidth, this.manHeight);

          //femalebody2
          this.game.load.spritesheet('femalebody2', FemaleClothes2, this.manWidth, this.manHeight);
          this.game.load.spritesheet('femalecap2', FemaleHead2, this.manWidth, this.manHeight);
          this.game.load.spritesheet('femaleskin2', FemaleSkin2, this.manWidth, this.manHeight);
        }
      }
    }

    function create() {
      let ref = this.game.add.group(),
        bgSprite,
        colorSprite,
        skinSprite;

      if (this.isFishery) {
        bgSprite = this.game.add.sprite(-20, 0, 'boatImg');
        colorSprite = this.game.add.sprite(-20, 0, 'boatRecolorImg');

        colorSprite.tint = '0x' + this.currentPlayer.color;

        ref.add(bgSprite);
        ref.add(colorSprite);

        ref.scale.setTo(this.avatarSize / this.boatImgWidth);
      }
      if (this.isMarket) {
        if (this.role === 'seller') {
          bgSprite = this.game.add.sprite(-20, -5, 'booth_bdy');
          colorSprite = this.game.add.sprite(-20, -5, 'booth_roof');

          colorSprite.tint = '0x' + this.currentPlayer.colors[0];

          ref.add(bgSprite);
          ref.add(colorSprite);

          ref.scale.setTo(this.avatarSize / this.boothSize);
        } else {
          const lastFrame = 119;
          bgSprite = this.game.add.sprite(
            -5,
            -5,
            this.currentPlayer.gender + 'body' + this.getPropNumber(this.currentPlayer, 'body'),
            lastFrame
          );
          colorSprite = this.game.add.sprite(
            -5,
            -5,
            this.currentPlayer.gender + 'cap' + this.getPropNumber(this.currentPlayer, 'cap'),
            lastFrame
          );
          skinSprite = this.game.add.sprite(
            -5,
            -5,
            this.currentPlayer.gender + 'skin' + this.getPropNumber(this.currentPlayer, 'skin'),
            lastFrame
          );

          colorSprite.tint = '0x' + this.currentPlayer.colors[1];
          bgSprite.tint = '0x' + this.currentPlayer.colors[0];

          ref.add(bgSprite);
          ref.add(skinSprite);
          ref.add(colorSprite);

          ref.x = (this.avatarSize - this.manWidth) / 2;

          ref.scale.setTo(this.avatarSize / this.manHeight);
        }
      }
    }
  }

  getPropNumber(player, bodypart) {
    if (bodypart === 'body') {
      return player?.clothprop;
    } else if (bodypart === 'skin') {
      return player?.skinprop;
    } else if (bodypart === 'cap') {
      return player?.headprop;
    }
  }

  render() {
    return <div id={"phaser-container-" + this.props.index} className="player-avatar"></div>;
  }
}

export default Avatar;