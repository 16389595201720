import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from '../../hooks';
import { useHistory } from 'react-router-dom';

const EndButton = observer(() => {
  const { gameStore } = useStores();
  const history = useHistory();

  function handleEndRoute() {
    const type = (gameStore.game.gameType === 'market') ? 'Market game' : 'Fishery game';
    window._paq.push(['trackEvent', type, 'End game', 'Ending game & viewing results']);

    gameStore.endRound(true);
    history.push('/admin/result');
  }

  return (
    <button onClick={handleEndRoute} className="btn btn-secondary" type="button">
      <i className="fa fa-stop"></i>Spiel beenden{' '}
      <small>& Ergebnisse ansehen</small>
    </button>
  );
});

export default EndButton;
