import { observer } from 'mobx-react';
import React from 'react';
import RoleName from '../../../components/player/roleName';
import { useStores } from '../../../hooks';
import UtilsService from '../../../services/utils';

const RunningMarket = observer(() => {
  const {
    gameStore: { game },
  } = useStores();

  function renderBuyers() {
    const buyers = [];
    for (const [uuid, player] of Object.entries(game.players)) {
      if (player.role === 'buyer') {
        buyers.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={uuid}
          >
            <span>
              Spieler &nbsp;
              <RoleName player={player} />
            </span>
          </li>
        );
      }
    }
    return buyers;
  }

  function renderSellers() {
    const sellers = [];
    for (const [uuid, player] of Object.entries(game.players)) {
      if (player.role === 'seller') {
        sellers.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={uuid}
          >
            <span>
              Spieler &nbsp;
              <RoleName player={player} />
            </span>
          </li>
        );
      }
    }
    return sellers;
  }

  function renderPartners() {
    const partners = [];
    for (const [uuid, player] of Object.entries(game.players)) {
      if (player.rounds.length === game.currentRound && player.role === 'buyer')
        partners.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={uuid}
          >
            <span>
              <RoleName player={player} /> &lt;&gt;{' '}
              <RoleName player={game.players[player.tradepartner]} />
            </span>
            <span className="badge badge-success badge-pill">
              {UtilsService.formatNumber(
                player.money - player.rounds[game.currentRound - 1]
              )}{' '}
              €
            </span>
          </li>
        );
    }
    return partners;
  }

  return (
    game ?
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <h3>Käufer</h3>
              <ul className="list-group">{renderBuyers()}</ul>
            </div>
            <div className="col">
              <h3>Verkäufer</h3>
              <ul className="list-group">{renderSellers()}</ul>
            </div>
          </div>
        </div>
        <div className="col">
          <h3>Handelspartner</h3>
          <ul className="list-group">{renderPartners()}</ul>
          {!renderPartners().length && (
            <i className="text-muted">Noch keine Partner gefunden</i>
          )}
        </div>
      </div> : null
  );
});

export default RunningMarket;
