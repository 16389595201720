import React from 'react';
import AdminStore from './admin.store';
import GameStore from './game.store';
import PlayingStore from './playing.store';
import PresentationStore from './presentation.store';

export class RootStore {
  constructor() {
    this.adminStore = new AdminStore(this);
    this.gameStore = new GameStore(this);
    this.playingStore = new PlayingStore(this);
    this.presentationStore = new PresentationStore(this);
  }
}

// Export React context for hooks
export const StoresContext = React.createContext();

export const StoresProvider = StoresContext.Provider;
